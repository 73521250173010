import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gallery: null,
    galleryIndex: 0,
    mobileGallery: null,
  },
  getters:{
    gallery: state => {
      return state.gallery;
    },
    galleryIndex: state => {
      return state.galleryIndex;
    },
    mobileGallery: state => {
      return state.mobileGallery;
    }
  },
  mutations: {
    gallery: function (state, newValue){
      state.gallery = newValue;
    },
    galleryIndex: function (state, newValue){
      state.galleryIndex = newValue;
    },
    setMobileGallery: function (state, newValue){
      state.mobileGallery = newValue;
    },
  },
  actions: {
  },
  modules: {
  }
})
