<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="mobileGallery" key="app-gallery">
        <MobileGallery/>
      </div>
      <div v-else>
        <Slider/>
        <About/>
        <Galleries/>
        <Contact/>
        <Footer/>
        <Navbar/>
      </div>
    </transition>


  </div>
</template>

<script>
import Navbar from "@/components/mobile/Navbar";
import Slider from "../sections/mobile/Slider";
import About from "../sections/mobile/About";
import Galleries from "../sections/mobile/Galleries";
import Contact from "../sections/mobile/Contact"
import Footer from "../sections/mobile/Footer";
import MobileGallery from "../components/mobile/MobileGallery";

export default {
  name: "Mobile.vue",
  components: {
    Galleries,
    Navbar,
    Slider,
    About,
    Contact,
    Footer,
    MobileGallery
  },
  computed: {
    mobileGallery: function () {
      return this.$store.getters.mobileGallery;
    }
  },
}
</script>

<style scoped>
  div{
    background-color: #FD0583;
    height: 1650vw;

    overflow-x: hidden;
  }
</style>