<template>
  <div class="about">

    <b-button class="btn-ellipse about__ellipse-first" @click="toPage(0)" :disabled="page === 0"/>
    <b-button class="btn-ellipse about__ellipse-second" @click="toPage(1)" :disabled="page === 1"/>
    <b-button class="btn-ellipse about__ellipse-third" @click="toPage(2)" :disabled="page === 2"/>

    <svg data-aos="fade-in" data-aos-delay="300" viewBox="0 0 34.803 25.605" @click="nextPage">
      <path
          d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
          transform="translate(0 -0.829)"/>
    </svg>

    <img data-aos="fade-left" id="about__field" src="../../assets/img/mask-group-1.webp" alt="about-field"/>
    <img data-aos="fade-right" id="about__field-right" src="../../assets/img/mask-group-1.webp" alt="about-field-right"/>
    <video data-aos="fade-bottom" id="about__waves" src="../../assets/anims/about-wave.webm" autoplay muted loop/>
    <img data-aos="fade-bottom" id="about__rasp" src="../../assets/img/malnalila.webp" alt="about-rasp"/>
    <img data-aos="fade-bottom" id="about__rasp-white" src="../../assets/img/malna-24.webp" alt="about-rasp-white"/>
    <div data-aos="fade-bottom" class="about__circle"/>


    <transition name="fade" mode="out-in">
        <div v-if="page === 0" key="about-first">
          <img class="about__img" src="../../assets/img/about-1.webp" alt="about-img-1"/>
          <img class="about__gradient" src="../../assets/img/about-gradient.webp" alt="about-gradient"/>
          <p class="about__text-title">
            Ti is úgy szeretitek a sütiket,
            <br>
            ahogy a nagymamátok készíti?
          </p>

          <p class="about__text-desc">
            Eredeti receptekből, gondoskodó odaadással készítjük a legfinomabb kézműves süteményeinket! A nagymamánk megőrizte kézzel írt receptjeit, amit jó hangulatú családi sütögetések alkalmával megtanított anyukánknak, aki most a következő két generációnak adja tovább…
          </p>

        </div>

        <div v-if="page === 1" key="about-second">
          <img class="about__img" src="../../assets/img/about-2.webp" alt="about-img-2"/>
          <img class="about__gradient" src="../../assets/img/about-gradient.webp" alt="about-gradient"/>
          <p class="about__text-title">
            Ti is úgy szeretitek a sütiket,
            <br>
            ahogy a nagymamátok készíti?
          </p>
          <p class="about__text-desc">
            Szeretnénk, ha ti is részesei lehetnétek annak, amiben nekünk volt részünk,ezért alapítottuk meg apró családi vállalkozásunkat, a Málna manufaktúra kézműves sütödét.
            <br/><br/>
            Tegyél egy próbát nálunk!
          </p>
        </div>
        <div v-if="page === 2" key="about-third">
          <img class="about__img" src="../../assets/img/about-3.webp" alt="about-img-3"/>
          <img class="about__gradient" src="../../assets/img/about-gradient.webp" alt="about-gradient"/>
          <p class="about__text-title">
            És, ha ízlett? Vigyél otthonra,
            <br>
            vagy a kollégáknak!
          </p>
          <p class="about__text-desc">
            Sőt! Szívesen segítünk rendezvényeken, családi összejöveteleken,lakodalmakon, céges összejöveteleken. Nagyobb mennyiségi igény esetén azonban egy héttel előre egyeztetnünk kell annak érdekében, hogy a legjobb minőséget tudjuk biztosítani minden falatnál.
          </p>
          <b-button v-scroll-to="{ el: '#contact__drips', offset: -150}" class="btn-outlined">Ajánlatot kérek</b-button>
        </div>
      </transition>


  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      page: 0
    }
  },
  methods: {
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 3) this.page = 0;
    },
    startVideo(){
      document.getElementById('about__waves').play();
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.about__gradient{
  position: absolute;
  -ms-transform: rotate(180.00deg);
  -webkit-transform: rotate(180.00deg);
  transform: rotate(180.00deg);
  width: 49.11vw;
  height: 44.63vw;
  left: 54.4vw;
  top: 71.56vw;
}
.btn-outlined{
  position: absolute;
  left: 21.54vw;
  top: 103.38vw;
}
.about__text-desc{
  position: absolute;
  text-align: left;
  color: white;
  font-size: 1.35vw;
  font-family: blackest_regular, serif;
  width: 28.125vw;
  height: 16.3vw;
  left: 21.45vw;
  top: 88.64vw;
}
.about__text-title{
  position: absolute;
  text-align: left;
  line-height: 2.13vw;
  color: white;
  font-size: 2vw;
  font-family: oleo-script_regular, serif;
  left: 21.45vw;
  top: 81.58vw;
}
.about__img {
  position: absolute;
  width: 48.69vw;
  height: 44.63vw;
  left: 54.4vw;
  top: 71.45vw;
}

.about svg {
  position: absolute;
  width: 1.8vw;
  height: 1.3vw;
  left: 6vw;
  top: 105.26vw;
  cursor: pointer;
  z-index: 2;
}

.about svg path {
  fill: white;
  transition: 0.3s;
}

.about svg:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}

.about__ellipse-second {
  position: absolute;
  left: 6.19vw;
  top: 89.1vw;
}

.about__ellipse-third {
  position: absolute;
  left: 6.19vw;
  top: 92.2vw;
}

.about__ellipse-first {
  position: absolute;
  left: 6.19vw;
  top: 86vw;
}

#about__rasp-white {
  position: absolute;
  width: 7.5vw;
  height: 8.22vw;
  left: 29vw;
  top: 108vw;
}

.about__circle {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 16.35vw;
  height: 16.35vw;
  left: 43.125vw;
  top: 89.21vw;

}

#about__rasp {
  position: absolute;
  width: 11.14vw;
  height: 12.18vw;
  left: 8.23vw;
  top: 69.32vw;
}

#about__waves {
  position: absolute;
  width: 40.26vw;
  height: 17.55vw;
  left: 40.73vw;
  top: 59.4vw;
}

#about__field {
  position: absolute;
  width: 42.44vw;
  height: 34.63vw;
  left: 13.64vw;
  top: 76.875vw;
}

#about__field-right {
  position: absolute;
  width: 42.44vw;
  height: 34.63vw;
  left: 53vw;
  top: 76.875vw;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>