<template>
  <div class="gallery-events">
    <transition name="fade" mode="out-in">
      <div v-if="!isOpened" key="gallery-events-closed">
        <div class="gallery-events__bg-closed"/>
        <img id="gallery-events__img" src="../../../assets/img/galleries/events/closed.webp" alt="gallery-events"/>
        <video id="gallery-events__anim" src="../../../assets/anims/gallery-events.webm" autoplay muted/>
        <p class="gallery-events__text" @click="toggleOpen">
          Rendezvények

          <svg viewBox="0 0 137.79 137.79">
            <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
            <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
            <path class="cls-3" d="M68,67.86Z"/>
            <path class="cls-4"
                  d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
          </svg>

        </p>
      </div>
      <div v-else key="gallery-events-opened">
        <div class="gallery-events__bg-opened"/>
        <svg class="svg__close" viewBox="0 0 98 98" @click="toggleOpen">
          <path class="path-close"
                d="M59,49,78.64,29.3a6.5,6.5,0,1,0-9.19-9.19L49.79,39.77,30.13,20.11a6.5,6.5,0,0,0-9.19,9.19L40.6,49,20.94,68.62a6.5,6.5,0,0,0,9.19,9.2L49.79,58.16,69.45,77.82a6.5,6.5,0,1,0,9.19-9.2Z"/>
        </svg>
        <p class="gallery-events__text-opened">Rendezvények</p>
        <img id="gallery-events__img-opened" src="../../../assets/img/galleries/events/opened.webp" alt="gallery-events-opened"/>
        <b-button class="btn-ellipse btn-ellipse-first" @click="toPage(0)" :disabled="page === 0"/>
        <b-button class="btn-ellipse btn-ellipse-second" @click="toPage(1)" :disabled="page === 1"/>
        <svg class="arrow-next" viewBox="0 0 34.803 25.605" @click="nextPage">
          <path
              d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
              transform="translate(0 -0.829)"/>
        </svg>

        <b-button class="btn-gallery btn-2019" @click="changeCategory(2019)" :disabled="category === 2019">
          -2019
        </b-button>
<!--        <b-button class="btn-gallery btn-2020" @click="changeCategory(2020)" :disabled="category === 2020">-->
<!--          2020-->
<!--        </b-button>-->

        <transition name="fade" mode="out-in">
          <div v-if="category === 2019" key="images-2019">
            <transition name="fade" mode="out-in">
              <div :key="page">
                <ImageBlock
                    :id="images2019[page * 4].id"
                    :src="images2019[page * 4].src"
                    :name="images2019[page * 4].name"
                    :image-array="images2019"
                    :index="page * 4"
                    topImage="372.86vw"
                    topMiddle="3.7vw"
                    variant="medium"
                    container-pos-left="0"
                    image-height="20.05vw"
                    :year="images2019[page * 4].year"
                />
                <ImageBlock
                    :id="images2019[page * 4 + 1].id"
                    :src="images2019[page * 4 + 1].src"
                    :name="images2019[page * 4 + 1].name"
                    :image-array="images2019"
                    :index="page * 4 + 1"
                    topImage="372.86vw"
                    topMiddle="3.7vw"
                    variant="medium"
                    container-pos-left="25vw"
                    image-height="20.05vw"
                    :year="images2019[page * 4 + 1].year"
                />
                <ImageBlock
                    :id="images2019[page * 4 + 2].id"
                    :src="images2019[page * 4 + 2].src"
                    :name="images2019[page * 4 + 2].name"
                    :image-array="images2019"
                    :index="page * 4 + 2"
                    topImage="372.86vw"
                    topMiddle="3.7vw"
                    variant="medium"
                    container-pos-left="50vw"
                    image-height="20.05vw"
                    :year="images2019[page * 4 + 2].year"
                />
                <ImageBlock
                  :id="images2019[page * 4 + 3].id"
                  :src="images2019[page * 4 + 3].src"
                  :name="images2019[page * 4 + 3].name"
                  :image-array="images2019"
                  :index="page * 4 + 3"
                  topImage="372.86vw"
                  topMiddle="3.7vw"
                  variant="medium"
                  container-pos-left="75vw"
                  image-height="20.05vw"
                  :year="images2019[page * 4 + 3].year"
              />
              </div>
            </transition>
          </div>

          <div v-else key="images-2020">
            <transition name="fade" mode="out-in">
            </transition>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import ImageBlock from "@/sections/desktop/Galleries/blocks/ImageBlock";

export default {
  name: "GalleryEvents",
  components: {
    ImageBlock,
  },
  data() {
    return {
      page: 0,
      category: 2019,
      isOpened: false,
      images2019: [
        {
          id: 'gallery-img-events-2019-0',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/first-0.webp'),
          srcGallery: require('@/assets/img/galleries/events/first-0-opened.webp'),
          year: 2017
        },
        {
          id: 'gallery-img-events-2019-1',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/first-1.webp'),
          srcGallery: require('@/assets/img/galleries/events/first-second-opened.webp'),
          year: 2017
        },
        {
          id: 'gallery-img-events-2019-2',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/first-2.webp'),
          srcGallery: require('@/assets/img/galleries/events/first-third-opened.webp'),
          year: 2018
        },
        {
          id: 'gallery-img-events-2019-3',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/first-3.webp'),
          srcGallery: require('@/assets/img/galleries/events/first-3-opened.webp'),
          year: 2018
        },
        {
          id: 'gallery-img-events-2019-4',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/second-0.webp'),
          srcGallery: require('@/assets/img/galleries/events/second-0-opened.webp'),
          year: 2019
        },
        {
          id: 'gallery-img-events-2019-5',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/second-1.webp'),
          srcGallery: require('@/assets/img/galleries/events/second-2-opened.webp'),
          year: 2019
        },
        {
          id: 'gallery-img-events-2019-6',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/second-2.webp'),
          srcGallery: require('@/assets/img/galleries/events/second-3-opened.webp'),
          year: 2019
        },
        {
          id: 'gallery-img-events-2019-7',
          name: 'Esküvő kiállítás',
          src: require('@/assets/img/galleries/events/second-4.webp'),
          srcGallery: require('@/assets/img/galleries/events/second-4-opened.webp'),
          year: 2019
        },
      ]
    }
  },
  methods: {
    changeCategory(category) {
      this.category = category;
      this.page = 0;
    },
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 2) this.page = 0;
    },
    toggleOpen() {
      this.page = 0;
      this.isOpened = !this.isOpened;
    }
  }
}
</script>

<style scoped>
.btn-2020 {
  position: absolute;
  left: 69.42vw;
  top: 366.66vw;
}
.btn-2019 {
  position: absolute;
  left: 59.53vw;
  top: 366.66vw;
}

.gallery-events .arrow-next {
  position: absolute;
  left: 82.43vw;
  top: 355.75vw;
  width: 1.8vw;
  height: 1.3vw;
  cursor: pointer;
}

.gallery-events .arrow-next path {
  fill: white;
  transition: 0.3s;
}

.gallery-events .arrow-next:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}
.btn-ellipse-first {
  position: absolute;
  left: 70.15vw;
  top: 355.76vw;
}

.btn-ellipse-second {
  position: absolute;
  left: 73.33vw;
  top: 355.76vw;
}
#gallery-events__img-opened{
  position: absolute;
  width: 112.55vw;
  height: 20.05vw;
  left: -59.06vw;
  top: 352.81vw;
}

.gallery-events__text-opened{
  position: absolute;
  line-height: 4.375vw;
  color: #ffffff;
  font-size: 4vw;
  font-family: blackest_heavy, serif;
  left: 59.53vw;
  top: 359.94vw;
  transition: 0.3s;
}

.svg__close {
  cursor: pointer;
  position: absolute;
  left: 55.82vw;
  top: 354.3vw;
  width: 4.32vw;
  height: 4.32vw;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.path-close {
  fill: white;
  transition: 0.3s;
}
.gallery-events__bg-opened {
  position: absolute;
  width: 100vw;
  height: 40.13vw;
  left: 0;
  top: 352.81vw;
  background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 55%);
}

.cls-1 {
  fill: #fc0584;
}

.cls-1, .cls-2, .cls-3 {
  stroke: #fc0584;
}

.cls-2, .cls-3 {
  fill: none;
}

.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 13px;
}

.cls-4 {
  fill: #fff;
  transition: 0.3s;
}

.gallery-events__text:hover .cls-4 {
  fill: #A91974;
  transition: 0.3s;
}

.gallery-events__text svg {
  position: absolute;
  left: 47.2vw;
  width: 6vw;
  top: 1.1vw;
}

.gallery-events__text {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  cursor: pointer;
  white-space: pre;
  width: 42.5vw;
  height: 7.55vw;
  left: 12.81vw;
  top: 377vw;
  transition: 0.3s;
}

.gallery-events__text:hover {
  color: var(--color-magenta);
  transition: 0.3s;
}
#gallery-events__anim {
  position: absolute;
  width: 88.31vw;
  height: 23.03vw;
  left: -13.7vw;
  top: 353.5vw;
}

#gallery-events__img {
  position: absolute;
  width: 43.75vw;
  height: 40.07vw;
  left: 62.95vw;
  top: 352.81vw;

}

.gallery-events__bg-closed {
  position: absolute;
  background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 67.41vw;
  height: 33.78vw;
  left: -4.24vw;
  top: 356.88vw;
}
</style>