<template>
  <div :id="id" class="img-container container-first" @click="openGallery">
    <img :id="`img-${id}`" alt="gallery-img-first"
         :src="src"/>

    <div class="d-flex justify-content-center">
      <div :id="`middle-${id}`" class="middle">
        <p :id="`text-${id}`" class="text">{{ name }}</p>

        <div class="d-flex justify-content-center">
          <svg :id="`svg-${id}`" viewBox="0 0 98 98">
            <path
                d="M56.29,42.46V14.66a6.5,6.5,0,0,0-13,0v27.8H15.49a6.5,6.5,0,0,0,0,13H43.29V83.27a6.5,6.5,0,0,0,13,0V55.46h27.8a6.5,6.5,0,0,0,0-13Z"/>
          </svg>
        </div>
        <div class="d-flex justify-content-center" v-if="!!year">
          <p :id="`year-${id}`" class="year">{{ year }}</p>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
export default {
name: "LeftFirst",
  props:{
    id: String,
    src: String,
    name: String,
    topMiddle: String,
    topImage: String,
    variant: String,
    containerPosLeft: String,
    index: Number,
    imageArray: Array,
    imageHeight: String,
    year: Number,
  },
  methods:{
    openGallery(){
      this.$store.commit('gallery', this.imageArray);
      this.$store.commit('galleryIndex', this.index);
    },
    initImageBlock(){
      document.getElementById(this.id).style.top = this.topImage;
      document.getElementById(`middle-${this.id}`).style.top = this.topMiddle;
      document.getElementById(this.id).style.left = this.containerPosLeft;

      if(this.variant === 'big'){
        if(!!this.imageHeight){
          document.getElementById(`img-${this.id}`).style.height = this.imageHeight;
        } else{
          document.getElementById(`img-${this.id}`).style.height = '41.19vw';
        }
        document.getElementById(`img-${this.id}`).style.width = '50vw';

        document.getElementById(`text-${this.id}`).style.fontSize = '3.5vw';
        document.getElementById(`svg-${this.id}`).style.width = '6vw';
        document.getElementById(`svg-${this.id}`).style.marginTop = '0.5vw';
        document.getElementById(this.id).style.width = '50vw';
      }

      if(this.variant === 'medium'){
        if(!!this.imageHeight){
          document.getElementById(`img-${this.id}`).style.height = this.imageHeight;
        } else{
          document.getElementById(`img-${this.id}`).style.height = '21.9vw';
        }
        document.getElementById(`img-${this.id}`).style.width = '25.1vw';
        document.getElementById(`text-${this.id}`).style.fontSize = '2.1vw';
        document.getElementById(`svg-${this.id}`).style.width = '4vw';
        document.getElementById(`svg-${this.id}`).style.marginTop = '0.5vw';
        document.getElementById(this.id).style.width = '25.1vw';
        if(!!this.year){
          document.getElementById(`year-${this.id}`).style.fontSize = '2.1vw';
          document.getElementById(`year-${this.id}`).style.marginTop = '1.7vw';

        }


      }
    }
  },
  mounted() {
    this.initImageBlock();
  }
}
</script>

<style scoped>
.img-container path {
  fill: white;
}

.img-container {
  position: relative;
  cursor: pointer;
}

.img-container img {
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  /*transform: translate(-50%, -50%);*/
  /*-ms-transform: translate(-50%, -50%);*/
  /*text-align: center;*/
}

.text, .year {
  color: white;
  font-family: blackest_text_regular, serif;
}

.img-container:hover img {
  opacity: 0.3;
}

.img-container:hover .middle {
  opacity: 1;
}

img {
  position: absolute;
  left: 0;
}

</style>