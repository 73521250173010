<template>
  <div>
    <div class="slide__2-ellipse-overley-big anim__fade-in-left-opacity-25 anim__duration-0-3"/>
    <div class="slide__2-ellipse-overley-medium anim__fade-in-left-opacity anim__duration-0-5"/>
    <div class="slide__2-ellipse-pink-left anim__fade-in-left anim__duration-0-7"/>
    <div class="slide__2-ellipse-pink-right anim__fade-in-bottom anim__duration-0-3"/>
    <div class="slide__2-ellipse-overlay-big-right anim__fade-in-right-opacity-25 anim__duration-0-5"/>
    <div class="slide__2-ellipse-overlay-medium-right anim__fade-in-right-opacity anim__duration-0-7"/>
    <div class="slide__2-gradient"/>
    <img id="slide__2-img" src="../../../assets/img/korslider3.webp" class="anim__fade-in-left anim__duration-0-7" alt="slide-3-img"/>
    <p class="slide__2-text-family anim__fade-in-right anim__duration-0-3">Családnak vagy cégnek?</p>
    <p class="slide__2-text-try anim__fade-in-right anim__duration-0-5">Tegyél egy próbát, kóstold meg!</p>
    <b-button v-scroll-to="{ el: '#contact__drips', offset: -150}" class="slide__2-btn btn-outlined anim__fade-in-bottom anim__duration-0-7">Ajánlatot kérek</b-button>

  </div>
</template>

<script>
export default {
name: "Third"
}
</script>

<style scoped>
.slide__2-btn{
  position: absolute;
  left: 78.9vw;
  top: 42.5vw;
}
.slide__2-text-try{
  position: absolute;
  text-align: right;
  line-height: 7.76vw;
  color: rgba(255, 255, 255, 1);
  font-size: 7.34vw;
  font-family: blackest_heavy, serif;
  width: 89.4vw;
  height: 20vw;
  left: 3.3vw;
  top: 24.5vw;
}
.slide__2-text-family{
  position: absolute;
  text-align: right;
  color: white;
  font-size: 3.43vw;
  font-family: blackest_regular, serif;
  width: 63.9vw;
  /* height: 85.00px; */
  left: 28.8vw;
  top: 20.3vw;
}
.slide__2-ellipse-overlay-medium-right{
  position: absolute;
  opacity: 0.33;
  background-color: rgba(255, 199, 227, 1);
  box-shadow: none;
  width: 26.6vw;
  height: 26.6vw;
  left: 71.5vw;
  top: 34.6vw;
  border-radius: 50%;

}
.slide__2-ellipse-overlay-big-right{
  position: absolute;
  border-radius: 50%;
  opacity: 0.3;
  background: linear-gradient(-90deg, transparent 50%, rgba(249, 249, 249, 0.8) 100%);
  width: 38.1vw;
  height: 38.1vw;
  left: 70.5vw;
  top: 26.83vw;

}
.slide__2-gradient{
  position: absolute;
  width: 100vw;
  height: 57vw;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, transparent 35%, rgba(249, 249, 249, 0.8) 100%);
}
.slide__2-ellipse-pink-right{
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 5, 130, 1);
  width: 9.8vw;
  height: 9.8vw;
  left: 85.3vw;
  top: 13vw;
}
#slide__2-img{
  position: absolute;
  border-radius: 50%;
  width: 57.65vw;
  height: 51.45vw;
  left: -5.8vw;
  top: 5.9vw;
}

.slide__2-ellipse-overley-big{
  position: absolute;
  border-radius: 50%;
  opacity: 0.65;
  background-color: rgba(255, 199, 227, 1);
  box-shadow: none;
  width: 76.25vw;
  height: 76.25vw;
  left: -8.75vw;
  top: -19.3vw;
}

.slide__2-ellipse-overley-medium{
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 199, 227, 1);
  width: 53.3vw;
  height: 53.3vw;
  left: 1.5vw;
  top: 2.5vw;
}

.slide__2-ellipse-pink-left{
  position: absolute;
  border-radius: 50%;
  width: 40.89vw;
  height: 40.89vw;
  left: 4.2vw;
  top: 10.1vw;
  background: linear-gradient(0deg, var(--color-magenta) 0%, #F9F9F9 100%);
}
</style>