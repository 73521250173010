import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios';
import BootstrapVue from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VueAxios from 'vue-axios';
import AOS from 'aos';


// import VueFullPage from 'vue-fullpage.js'
let VueScrollTo = require('vue-scrollto');

import "@/assets/css/fonts.css";
import "@/assets/css/global.css";
import "@/assets/css/animations.css";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'aos/dist/aos.css';

AOS.init({once: true})
Vue.use(VueScrollTo, {
  container: "body",
  duration: 1500,
})

// Vue.use(VueFullPage);
Vue.use(BootstrapVue);
Vue.use(Vue2TouchEvents, {
  swipeTolerance: 100
});

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    async apiRequest(method, route, data = null, headersProp = null) {
      let headers = {};
      if(!!headersProp){
        headers = headersProp
      }

      let address = `https://api.malnamanufaktura.hu/api${route}`
      let parameters;
      let output;
      parameters = {
        method: method,
        url: address,
        data: data,
        headers: headers
      }
      await this.axios(parameters).then((response) => {
        output = response.data;
      }).catch((errors) => {
        console.log('errors', errors);
      });
      return output;
    },
  }
})

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
