<template>
  <div class="raspberries">
    <div class="raspberries__ellipse-bottom"/>
    <!--    <div :class="{ 'btn-left-active': left}">-->
    <!--      <b-button @click="toggleLeft" class="btn-left btn-ellipse"/>-->
    <!--    </div>-->

    <!--    <div :class="{ 'btn-right-active': right}">-->
    <!--      <b-button @click="toggleRight" class="btn-right btn-ellipse"/>-->
    <!--    </div>-->

    <img id="rasperries__pink" src="../../assets/img/malna-pink.webp" alt="rasp-pink"/>
    <img  data-aos="fade-in" id="rasperries__img" src="../../assets/img/rolunk.webp" alt="img-about"/>

    <div data-aos="fade-right" class="ellipse-left"/>
    <p data-aos="fade-right" data-aos-delay="200" class="ellipse-title-left">Dr. Balogh Ibolya</p>

    <div data-aos="fade-left" data-aos-delay="400" class="ellipse-right"/>
    <p data-aos="fade-left" data-aos-delay="600" class="ellipse-title-right">Gombaszögi Nóra</p>


  </div>
</template>

<script>
export default {
  name: "Rasperries",
  data() {
    return {
      left: false,
      right: false
    }
  },
  methods: {
    toggleLeft() {
      this.left = !this.left;
    },
    toggleRight() {
      this.right = !this.right;
    },
  },
  mounted() {
    setInterval(() => {
      this.left = true
    }, 300);
    setInterval(() => {
      this.right = true
    }, 800);
  }
}
</script>

<style scoped>
.raspberries__ellipse-bottom {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 28.33vw;
  height: 28.33vw;
  left: 74.375vw;
  top: 212.1875vw;
}

.btn-left-active .btn-left, .btn-right-active .btn-right {
  background-color: white !important;
}

.btn-left-active .btn-ellipse:hover, .btn-right-active .btn-ellipse:hover {
  background-color: var(--color-violet) !important;
}

.ellipse-title-right {
  position: absolute;
  color: white;
  left: 65vw;
  top: 191vw;
  font-size: 2.5vw;
  font-family: oleo-script_regular, serif;
}

.ellipse-title-left {
  position: absolute;
  color: white;
  left: 20vw;
  top: 191vw;
  font-size: 2.5vw;
  font-family: oleo-script_regular, serif;
}


.ellipse-right {
  position: absolute;
  left: 63.5vw;
  top: 188.38vw;
  background-color: var(--color-magenta);
  opacity: 0.5;
  width: 8.7vw;
  height: 8.7vw;
  border-radius: 50%;
}

.ellipse-left {
  position: absolute;
  left: 30vw;
  top: 188.38vw;
  background-color: var(--color-magenta);
  opacity: 0.5;
  width: 8.7vw;
  height: 8.7vw;
  border-radius: 50%;
}

.btn-right {
  position: absolute;
  left: 91.56vw;
  top: 198.38vw;
}

.btn-left {
  position: absolute;
  left: 8.85vw;
  top: 198.38vw;
}

#rasperries__img {
  position: absolute;
  width: 68.85vw;
  height: 44.47vw;
  left: 16.04vw;
  top: 175.62vw;
}

#rasperries__pink {
  position: absolute;
  width: 13.22vw;
  height: 14.47vw;
  left: 76.06vw;
  top: 167.13vw;
}
</style>