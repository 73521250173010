<template>
  <div v-touch:swipe.left="nextPage" v-touch:swipe.right="prevPage" id="about">

    <img id="about__malna" src="../../assets/img/malnalila-12.png" alt="malna-lila"/>

    <transition name="fade" mode="out-in">
      <div v-if="page === 0" key="about-first">
        <p class="about__text-title">
          Ti is úgy szeretitek a sütiket,
          <br>
          ahogy a nagymamátok készíti?
        </p>

        <p class="about__text-desc">
          Eredeti receptekből, gondoskodó <br/>odaadással készítjük a legfinomabb<br/> kézműves süteményeinket! A <br/>nagymamánk megőrizte kézzel írt<br/> receptjeit, amit jó hangulatú családi<br/> sütögetések alkalmával megtanított<br/> anyukánknak, aki most a következő<br/> két generációnak adja tovább…
        </p>
        <img class="about__img" src="../../assets/img/about-1.png" alt="about-img-0"/>
      </div>
      <div v-if="page === 1" key="about-second">
        <p class="about__text-title">
          Ti is úgy szeretitek a sütiket,
          <br>
          ahogy a nagymamátok készíti?
        </p>
        <p class="about__text-desc">
          Szeretnénk, ha ti is részesei<br/> lehetnétek annak, amiben nekünk<br/> volt részünk,ezért alapítottuk meg<br/> apró családi vállalkozásunkat, a<br/> Málna manufaktúra kézműves<br/> sütödét.
          <br/><br/>
          Tegyél egy próbát nálunk!
        </p>
        <img class="about__img" src="../../assets/img/about-2.png" alt="about-img-1"/>
      </div>
      <div v-if="page === 2" key="about-third">
        <p class="about__text-title">
          És, ha ízlett? Vigyél otthonra,
          <br>
          vagy a kollégáknak!
        </p>
        <p class="about__text-desc">
          Sőt! Szívesen segítünk<br/> rendezvényeken, családi<br/> összejöveteleken,lakodalmakon,<br/> céges összejöveteleken. Nagyobb <br/>mennyiségi igény esetén azonban<br/> egy héttel előre egyeztetnünk kell<br/> annak érdekében, hogy a legjobb <br/>minőséget tudjuk biztosítani minden<br/> falatnál.
        </p>
        <img class="about__img" src="../../assets/img/about-3.png" alt="about-img-2"/>
      </div>
    </transition>

    <b-button class="btn-ellipse slider__pagination-first" @click="toPage(0)" :disabled="page === 0"/>
    <b-button class="btn-ellipse slider__pagination-second" @click="toPage(1)" :disabled="page === 1"/>
    <b-button class="btn-ellipse slider__pagination-third" @click="toPage(2)" :disabled="page === 2"/>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      page: 0,
    }
  },
  methods: {
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 3) this.page = 0;
    },
    prevPage() {
      this.page--;
      if (this.page === -1) this.page = 2;
    }
  },
}
</script>

<style scoped>
.about__img{
  position: absolute;
  width: 102.5vw;
  height: 102.5vw;
  left: 0;
  top: 288.05vw;
}
.about__text-desc{
  position: absolute;
  text-align: left !important;
  line-height: 4.72vw;
  color: rgba(255, 255, 255, 1);
  font-size: 3.88vw;
  font-weight: 400;
  font-family: blackest_regular, Arial;
  white-space: pre;
  width: 65.27vw;
  height: 38.33vw;
  left: 20.55vw;
  top: 220.83vw;
}
.about__text-title{
  position: absolute;
  text-align: left;
  line-height: 5.27vw;
  color: rgba(255, 255, 255, 1);
  font-size: 5vw;
  font-weight: 400;
  font-family: oleo-script_regular, Arial;
  overflow-y: unset;
  white-space: pre;
  width: 59.16vw;
  height: 12.22vw;
  left: 20.55vw;
  top: 205vw;
}
#about__malna {
  position: absolute;
  width: 21.11vw;
  height: 22.77vw;
  left: 8.33vw;
  top: 182.77vw;
}

.slider__pagination-first {
  position: absolute;
  left: 38.33vw;
  top: 270.27vw;
}

.slider__pagination-second {
  position: absolute;
  left: 47.77vw;
  top: 270.27vw;
}

.slider__pagination-third {
  position: absolute;
  left: 57.22vw;
  top: 270.27vw;
}
</style>