<template>
  <div>
    <div class="slide__0-ellipse-left-overlay anim__fade-in-left-opacity anim__duration-0-1"/>
    <div class="slide__0-ellipse-left-violet ellipse-bg-gradient anim__fade-in-left anim__duration-0-3"/>
    <div class="slide__0-ellipse-right-violet-tiny anim__fade-in-bottom anim__duration-0-3 ellipse-bg-gradient"/>
    <div class="slide__0-ellipse-right-violet-medium anim__fade-in-bottom anim__duration-0-5 ellipse-bg-gradient"/>
    <div class="slide__0-ellipse-right-overlay anim__fade-in-right-opacity anim__duration-0-5"/>
    <img id="slide__0-cake" class="anim__fade-in-left anim__duration-0-5" src="../../../assets/img/slider1torta.webp" alt="slider-0-cake"/>
    <p class="slide__0-text-welcome anim__fade-in-right anim__duration-0-3">Üdvözlünk!</p>
    <p class="slide__0-text-title anim__fade-in-right anim__duration-0-5">Lásd rózsaszínben <br>a világot!</p>
    <b-button v-scroll-to="{ el: '#contact__drips', offset: -150}" class="slide__0-btn btn-outlined anim__fade-in-bottom anim__duration-0-7">Ajánlatot kérek!</b-button>
  </div>
</template>

<script>
export default {
name: "First"
}
</script>

<style scoped>
.slide__0-btn {
  position: absolute;

  left: 78.9vw;
  top: 42.5vw;
}

.slide__0-text-welcome {
  position: absolute;
  text-align: right;
  color: rgba(255, 255, 255, 1);
  font-size: 3.5vw;
  font-family: blackest_regular, serif;
  width: 64vw;
  left: 28.75vw;
  top: 20.5vw;
}

.slide__0-text-title {
  position: absolute;
  text-align: right;
  line-height: 6.9vw;
  color: white;
  font-size: 7.3vw;
  font-family: blackest_heavy, serif;
  width: 78.9vw;
  height: 18.1vw;
  left: 13.7vw;
  top: 25vw;
}

.slide__0-ellipse-left-overlay {
  position: absolute;
  width: 89vw;
  height: 89vw;
  left: -30vw;
  top: -27vw;
  background-color: white;
  opacity: 0.15;
  border-radius: 50%;
}

.slide__0-ellipse-left-violet {
  position: absolute;
  width: 70vw;
  height: 70vw;
  left: -16vw;
  top: -10vw;
  border-radius: 50%;
}

.slide__0-ellipse-right-violet-tiny {
  position: absolute;
  width: 2.3vw;
  height: 2.3vw;
  left: 88vw;
  top: 11vw;
  border-radius: 50%;
}

.slide__0-ellipse-right-violet-medium {
  position: absolute;
  border-radius: 50%;
  width: 7.3vw;
  height: 7.3vw;
  left: 70vw;
  top: 22.5vw;
}

.slide__0-ellipse-right-overlay {
  position: absolute;
  opacity: 0.15;
  background-color: white;
  width: 35.7vw;
  height: 35.7vw;
  left: 78vw;
  top: 29vw;
  border-radius: 50%;
}

#slide__0-cake {
  position: absolute;
  width: 62.5vw;
  height: 60vw;
  left: -11vw;
  top: -1.9vw;
}
</style>