<template>
    <div id="gallery">
        <div class="gallery__block-cookie" @click="openGallery('cookie')">
            <div class="gallery__cookie-bg"/>
            <img id="gallery__cookie" src="../../assets/img/gallery-cookie.png" alt="gallery-cookie"/>
            <img id="gallery__cookie-cover" src="../../assets/img/gallery-cookie-cover.png" alt="gallery-cookie-cover"/>
            <p class="gallery__cookie-title">Aprósütemény</p>
            <svg viewBox="0 0 137.79 137.79">
                <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
                <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
                <path class="cls-3" d="M68,67.86Z"/>
                <path class="cls-4"
                      d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
            </svg>
        </div>

        <div class="gallery__block-birthday" @click="openGallery('birthday')">
            <div class="gallery__birthday-bg"/>
            <img id="gallery__birthday" src="../../assets/img/gallery-birthday.png" alt="gallery-birthday"/>
            <img id="gallery__birthday-cover" src="../../assets/img/gallery-birthday-cover.png" alt="gallery-birthday-cover"/>
            <p class="gallery__birthday-title">Szülinapi torta</p>
            <svg viewBox="0 0 137.79 137.79">
                <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
                <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
                <path class="cls-3" d="M68,67.86Z"/>
                <path class="cls-4"
                      d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
            </svg>
        </div>

        <div class="gallery__block-wedding" @click="openGallery('wedding')">
            <div class="gallery__wedding-bg"/>
            <img id="gallery__wedding" src="../../assets/img/gallery-wedding.png" alt="gallery-wedding"/>
            <img id="gallery__wedding-cover" src="../../assets/img/gallery-wedding-cover.png" alt="gallery-wedding-cover"/>
            <p class="gallery__wedding-title">Esküvői torta</p>
            <svg viewBox="0 0 137.79 137.79">
                <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
                <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
                <path class="cls-3" d="M68,67.86Z"/>
                <path class="cls-4"
                      d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
            </svg>
        </div>

        <div class="gallery__block-events" @click="openGallery('events')">
            <div class="gallery__events-bg"/>
            <img id="gallery__events" src="../../assets/img/gallery-events.png" alt="gallery-events"/>
            <img id="gallery__events-cover" src="../../assets/img/gallery-events-cover.png" alt="gallery-events-cover"/>
            <p class="gallery__events-title">Rendezvények</p>
            <svg viewBox="0 0 137.79 137.79">
                <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
                <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
                <path class="cls-3" d="M68,67.86Z"/>
                <path class="cls-4"
                      d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
            </svg>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Galleries",
        data() {
            return {
                imagesCookie: [
                    {
                        id: 'gallery-img-sweet-0',
                        name: 'Mandulás színes linzer',
                        src: require('@/assets/img/galleries/cookie/first-0.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/0.webp')
                    },
                    {
                        id: 'gallery-img-sweet-1',
                        name: 'Diócska',
                        src: require('@/assets/img/galleries/cookie/first-1.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/1.webp')
                    },
                    {
                        id: 'gallery-img-sweet-2',
                        name: 'Gyümölcskosárka',
                        src: require('@/assets/img/galleries/cookie/first-2.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/2.webp')
                    },
                    {
                        id: 'gallery-img-sweet-3',
                        name: 'Meggyes pohárkrém',
                        src: require('@/assets/img/galleries/cookie/second-0.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/3.webp')
                    },

                    {
                        id: 'gallery-img-sweet-4',
                        name: 'Macaron',
                        src: require('@/assets/img/galleries/cookie/second-1.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/4.webp')
                    },

                    {
                        id: 'gallery-img-sweet-5',
                        name: 'Kútban kelt kifli',
                        src: require('@/assets/img/galleries/cookie/second-2.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/5.webp')
                    },

                    {
                        id: 'gallery-img-sweet-6',
                        name: 'Bejgli',
                        src: require('@/assets/img/galleries/cookie/third-0.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/6.webp')
                    },
                    {
                        id: 'gallery-img-sweet-7',
                        name: 'Kókuszgolyó',
                        src: require('@/assets/img/galleries/cookie/third-1.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/7.webp')
                    },
                    {
                        id: 'gallery-img-sweet-8',
                        name: 'Hólabda',
                        src: require('@/assets/img/galleries/cookie/third-2.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery/8.webp')
                    },
                    {
                        id: 'gallery-img-salty-0',
                        name: 'Sajtos rúd',
                        src: require('@/assets/img/galleries/cookie/salty-0.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/0.webp')
                    },
                    {
                        id: 'gallery-img-salty-1',
                        name: 'Sajtos pogácsa',
                        src: require('@/assets/img/galleries/cookie/salty-1.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/1.webp')
                    },
                    {
                        id: 'gallery-img-salty-2',
                        name: 'Croissant',
                        src: require('@/assets/img/galleries/cookie/salty-2.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/2.webp')
                    },
                    {
                        id: 'gallery-img-salty-3',
                        name: 'Kiflicske',
                        src: require('@/assets/img/galleries/cookie/salty-3.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/3.webp')
                    },
                    {
                        id: 'gallery-img-salty-4',
                        name: 'Pizza',
                        src: require('@/assets/img/galleries/cookie/salty-4.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/4.webp')
                    },
                    {
                        id: 'gallery-img-salty-5',
                        name: 'Töltött pogácsa',
                        src: require('@/assets/img/galleries/cookie/salty-5.webp'),
                        srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/5.webp')
                    }
                ],
                imagesEvents: [
                    {
                        id: 'gallery-img-events-2019-0',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/first-0.webp'),
                        srcGallery: require('@/assets/img/galleries/events/first-0-opened.webp'),
                        year: 2017
                    },
                    {
                        id: 'gallery-img-events-2019-1',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/first-1.webp'),
                        srcGallery: require('@/assets/img/galleries/events/first-second-opened.webp'),
                        year: 2017
                    },
                    {
                        id: 'gallery-img-events-2019-2',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/first-2.webp'),
                        srcGallery: require('@/assets/img/galleries/events/first-third-opened.webp'),
                        year: 2018
                    },
                    {
                        id: 'gallery-img-events-2019-3',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/first-3.webp'),
                        srcGallery: require('@/assets/img/galleries/events/first-3-opened.webp'),
                        year: 2018
                    },
                    {
                        id: 'gallery-img-events-2019-4',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/second-0.webp'),
                        srcGallery: require('@/assets/img/galleries/events/second-0-opened.webp'),
                        year: 2019
                    },
                    {
                        id: 'gallery-img-events-2019-5',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/second-1.webp'),
                        srcGallery: require('@/assets/img/galleries/events/second-2-opened.webp'),
                        year: 2019
                    },
                    {
                        id: 'gallery-img-events-2019-6',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/second-2.webp'),
                        srcGallery: require('@/assets/img/galleries/events/second-3-opened.webp'),
                        year: 2019
                    },
                    {
                        id: 'gallery-img-events-2019-7',
                        name: 'Esküvő kiállítás',
                        src: require('@/assets/img/galleries/events/second-4.webp'),
                        srcGallery: require('@/assets/img/galleries/events/second-4-opened.webp'),
                        year: 2019
                    },
                ],
                imagesWedding: [
                    {
                        id: 'gallery-img-wedding-0',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/first-0.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/first-0-opened.webp')
                    },
                    {
                        id: 'gallery-img-wedding-1',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/first-1.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/first-1-opened.webp')
                    },
                    {
                        id: 'gallery-img-wedding-2',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/first-2.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/first-2-opened.webp')
                    },
                    {
                        id: 'gallery-img-wedding-3',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/second-0.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/second-0-opened.webp')
                    },

                    {
                        id: 'gallery-img-wedding-4',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/second-1.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/second-1-opened.webp')
                    },

                    {
                        id: 'gallery-img-wedding-5',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/second-2.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/second-2-opened.webp')
                    },

                    {
                        id: 'gallery-img-wedding-6',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/third-0.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/third-0-opened.webp')
                    },
                    {
                        id: 'gallery-img-wedding-7',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/third-1.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/third-1-opened.webp')
                    },
                    {
                        id: 'gallery-img-wedding-8',
                        name: '',
                        src: require('@/assets/img/galleries/wedding/third-3.webp'),
                        srcGallery: require('@/assets/img/galleries/wedding/third-2-opened.webp')
                    },
                ],
                imagesBirthday: [
                    {
                        id: 'gallery-img-birthday-0',
                        name: 'Citrom torta',
                        src: require('@/assets/img/galleries/birthday/first-0.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/first-0-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-1',
                        name: 'Dobos torta',
                        src: require('@/assets/img/galleries/birthday/first-1.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/first-1-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-2',
                        name: 'Csoki torta',
                        src: require('@/assets/img/galleries/birthday/szulinapi-01.png'),
                        srcGallery: require('@/assets/img/galleries/birthday/first-2-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-3',
                        name: 'Oreo torta',
                        src: require('@/assets/img/galleries/birthday/second-0.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/second-0-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-4',
                        name: 'Málnás-csokis macaronos',
                        src: require('@/assets/img/galleries/birthday/second-1.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/second-1-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-5',
                        name: 'Oroszkrém torta',
                        src: require('@/assets/img/galleries/birthday/second-2.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/second-2-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-6',
                        name: 'Répatorta',
                        src: require('@/assets/img/galleries/birthday/third-0.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/third-0-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-7',
                        name: 'Erdei gyümölcsös',
                        src: require('@/assets/img/galleries/birthday/third-1.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/third-1-opened.webp')
                    },
                    {
                        id: 'gallery-img-birthday-8',
                        name: 'Sajt torta',
                        src: require('@/assets/img/galleries/birthday/third-2.webp'),
                        srcGallery: require('@/assets/img/galleries/birthday/third-2-opened.webp')
                    },
                ]
            }
        },
        methods:{
            openGallery(galleryType){
                // this.$store.commit('galleryIndex', 0);
                if(galleryType === 'cookie'){
                    this.$store.commit('setMobileGallery', this.imagesCookie);
                }
                if(galleryType === 'birthday'){
                    this.$store.commit('setMobileGallery', this.imagesBirthday);
                }
                if(galleryType === 'wedding'){
                    this.$store.commit('setMobileGallery', this.imagesWedding);
                }
                if(galleryType === 'events'){
                    this.$store.commit('setMobileGallery', this.imagesEvents);
                }
            }
        }
    }
</script>

<style scoped>
    .gallery__block-events svg{
        position: absolute;
        fill: rgba(252, 5, 132, 1);
        left: 40.55vw;
        top: 994.16vw;
    }

    .gallery__events-title{
        position: absolute;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10vw;
        font-weight: 600;
        font-family: blackest_heavy, Arial;
        white-space: pre;
        width: 70vw;
        height: 13.33vw;
        left: 15vw;
        top: 978.88vw;
    }
    #gallery__events-cover{
        position: absolute;
        width: 100vw;
        height: 105vw;
        left: 0;
        top: 1002.77vw;
    }
    #gallery__events{
        position: absolute;
        width: 160.55vw;
        height: 40.27vw;
        left: -49.72vw;
        top: 939.16vw;
    }

    .gallery__events-bg{
        position: absolute;
        width: 100vw;
        height: 81.34vw;
        left: 0;
        top: 939.44vw;
        background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
    }

    .gallery__block-wedding svg{
        position: absolute;
        fill: rgba(252, 5, 132, 1);
        left: 40.55vw;
        top: 815.27vw;
    }

    .gallery__wedding-title{
        position: absolute;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10vw;
        font-weight: 600;
        font-family: blackest_heavy, Arial;
        white-space: pre;
        width: 66.66vw;
        height: 13.33vw;
        left: 15.55vw;
        top: 800vw;
    }
    #gallery__wedding-cover{
        position: absolute;
        width: 100vw;
        height: 105vw;
        left: 0;
        top: 823.88vw;
    }
    #gallery__wedding{
        position: absolute;
        width: 160.55vw;
        height: 40.27vw;
        left: -30.27vw;
        top: 760.55vw;
    }

    .gallery__wedding-bg{
        position: absolute;
        width: 100vw;
        height: 81.34vw;
        left: 0;
        top: 760.45vw;
        background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
    }

    .gallery__block-birthday svg{
        position: absolute;
        fill: rgba(252, 5, 132, 1);
        left: 40.55vw;
        top: 636.66vw;
    }

    .gallery__birthday-title{
        position: absolute;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10vw;
        font-weight: 600;
        font-family: blackest_heavy, Arial;
        white-space: pre;
        width: 75vw;
        height: 13.33vw;
        left: 11.38vw;
        top: 621.38vw;
    }
    #gallery__birthday-cover{
        position: absolute;
        width: 100vw;
        height: 105vw;
        left: 0.00px;
        top: 645.27vw;
    }
    #gallery__birthday{
        position: absolute;
        width: 160.55vw;
        height: 40.55vw;
        left: -41.11vw;
        top: 582.22vw;
    }
    .gallery__birthday-bg{
        position: absolute;
        width: 100vw;
        height: 81.34vw;
        left: 0;
        top: 581.94vw;
        background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
    }

    .gallery__block-cookie svg{
        position: absolute;
        fill: rgba(252, 5, 132, 1);
        left: 40.55vw;
        top: 459.16vw;
    }

    svg{
        width: 17.22vw;
        height: 17.22vw;
    }
    .cls-1 {
        fill: #fc0584;
    }

    .cls-1, .cls-2, .cls-3 {
        stroke: #fc0584;
    }

    .cls-2, .cls-3 {
        fill: none;
    }

    .cls-3 {
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 3.61vw;
    }

    .cls-4 {
        fill: #fff;
    }
    .gallery__cookie-title{
        position: absolute;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10vw;
        font-weight: 600;
        font-family: blackest_heavy, Arial;
        white-space: pre;
        width: 72.22vw;
        height: 13.33vw;
        left: 12.77vw;
        top: 443.88vw;
    }
    #gallery__cookie-cover{
        position: absolute;
        width: 100vw;
        height: 105vw;
        left: 0.00px;
        top: 467.5vw;
    }
    #gallery__cookie{
        position: absolute;
        width: 160.55vw;
        height: 40.27vw;
        left: -43.88vw;
        top: 404.72vw;
    }
.gallery__cookie-bg{
    position: absolute;
    width: 100vw;
    height: 81.34vw;
    left: 0;
    top: 404.33vw;
    background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
}
</style>