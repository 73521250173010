<template>
  <div class="navbar__mobile">

    <transition name="fade" mode="out-in">
      <div class="navbar__mobile-menu" v-if="isOpened" key="menu-opened">
        <p @click="scrollToElement({ el: '#about__malna', offset: -100})">
          <svg class="svg-arrow" viewBox="0 0 43 58">
            <path
                d="M40.66,20.62,22.84,2.8h0a2.11,2.11,0,0,0-.23-.19,2.17,2.17,0,0,0-1.35-.49h-.12l-.19,0a2.48,2.48,0,0,0-.27,0l-.16.05-.28.09-.15.09a2,2,0,0,0-.25.14l-.14.12-.21.18,0,0L1.4,22.48a2.35,2.35,0,0,0,.14,3.31,2.31,2.31,0,0,0,1.58.62,2.33,2.33,0,0,0,1.73-.75l14-15.23L19,55a2.35,2.35,0,0,0,2.34,2.34h0A2.34,2.34,0,0,0,23.72,55l-.17-44.82,13.8,13.8a2.34,2.34,0,0,0,3.31-3.31Z"/>
          </svg>
          Rólunk
        </p>
        <p @click="scrollToElement({ el: '#gallery__cookie', offset: -100 })">
          <svg class="svg-arrow" viewBox="0 0 43 58">
            <path
                d="M40.66,20.62,22.84,2.8h0a2.11,2.11,0,0,0-.23-.19,2.17,2.17,0,0,0-1.35-.49h-.12l-.19,0a2.48,2.48,0,0,0-.27,0l-.16.05-.28.09-.15.09a2,2,0,0,0-.25.14l-.14.12-.21.18,0,0L1.4,22.48a2.35,2.35,0,0,0,.14,3.31,2.31,2.31,0,0,0,1.58.62,2.33,2.33,0,0,0,1.73-.75l14-15.23L19,55a2.35,2.35,0,0,0,2.34,2.34h0A2.34,2.34,0,0,0,23.72,55l-.17-44.82,13.8,13.8a2.34,2.34,0,0,0,3.31-3.31Z"/>
          </svg>
          Galéria
        </p>
        <p @click="scrollToElement({ el: '#contact__drip', offset: -100})">
          <svg class="svg-arrow" viewBox="0 0 43 58">
            <path
                d="M40.66,20.62,22.84,2.8h0a2.11,2.11,0,0,0-.23-.19,2.17,2.17,0,0,0-1.35-.49h-.12l-.19,0a2.48,2.48,0,0,0-.27,0l-.16.05-.28.09-.15.09a2,2,0,0,0-.25.14l-.14.12-.21.18,0,0L1.4,22.48a2.35,2.35,0,0,0,.14,3.31,2.31,2.31,0,0,0,1.58.62,2.33,2.33,0,0,0,1.73-.75l14-15.23L19,55a2.35,2.35,0,0,0,2.34,2.34h0A2.34,2.34,0,0,0,23.72,55l-.17-44.82,13.8,13.8a2.34,2.34,0,0,0,3.31-3.31Z"/>
          </svg>
          Kapcsolat
        </p>
      </div>
    </transition>

    <div class="d-flex justify-content-center">
      <img id="logo" src="../../assets/img/logo.webp" alt="logo"/>
    </div>

    <div class="hamburger">
      <div class="hamburger-icon"
           @click="toggleHamburger">
        <template v-for="(item, index) in 4">
          <span/>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      isOpened: false,
    }
  },
  methods: {
    scrollToElement(el){
      this.$scrollTo(el.el, 1000, {offset: el.offset});
      this.toggleHamburger();
    },
    toggleHamburger() {
      this.isOpened = !this.isOpened;
      let hamburger = document.getElementsByClassName("hamburger-icon");
      for (let i = 0; i < hamburger.length; i++) {
        this.isOpened ? hamburger[i].classList.toggle('open') :
            hamburger[i].classList.remove('open');
      }
    },
    scrollEvent() {
      if (window.scrollY > 10) {
        document.getElementById('logo').classList.add('logo-animation');
        document.getElementById('waves').classList.add('waves-animation');
      }
      if (window.scrollY < 300) {
        document.getElementById('logo').classList.remove('logo-animation');
        document.getElementById('waves').classList.remove('waves-animation');
      }
    }
  },
  mounted() {
    // document.addEventListener('scroll', this.scrollEvent);
  }
}
</script>

<style scoped>

.svg-arrow path {
  fill: var(--color-magenta);
}

.svg-arrow {
  width: 3.5vw;
  /*height: 10.83vw;*/
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  margin-top: -1vw;
  margin-right: 2vw;
}
.navbar__mobile-menu p{
  text-align: left;
  color: #fff;
  font-size: 23px;
  font-weight: 400;
  font-style: normal;
  font-family: blackest_regular,Arial,serif;
  white-space: pre;
  margin-left: 7vw;
}
.navbar__mobile-menu{
  background: linear-gradient(180deg, rgba(142,39,242,1) 0%, rgba(216,22,134,0) 100%);
  width: 50vw;
  height: 140vw;
  right: 0;
  top: 22.22vw;
  padding-top: 20vw;
  position: absolute;
}
.hamburger {
  position: absolute;
  top: 8vw;
  right: 5vw;
  z-index: 2;
}

.hamburger-icon {
  width: 6.1vw;
  height: 1vw;
  /*position: relative;*/
  /*margin: 32px auto;*/
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

}


.hamburger-icon span {
  background: #FD0583;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.hamburger-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-icon span:nth-child(2), .hamburger-icon span:nth-child(3) {
  top: 2vw;
}

.hamburger-icon span:nth-child(4) {
  top: 4vw;
}

.hamburger-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navbar__mobile {
  overflow: initial !important;
  width: 100vw;
  height: 22.22vw;
  background-color: white;
  position: fixed;
  top: 0;
  border-bottom: #FD0583 5px solid;
}

#logo {
  width: 46.94vw;
  /*position: absolute;*/
  z-index: 2;
  margin-top: -8.5vw;
  transition: all linear .2s
}
</style>