<template>
  <div>
    <div class="slide__2-ellipse-overley-big "/>
    <div class="slide__2-ellipse-overley-medium "/>
    <div class="slide__2-ellipse-pink-left"/>


    <div class="slide__2-ellipse-overlay-big-right "/>
    <div class="slide__2-ellipse-overlay-medium-right  "/>
    <div class="slide__2-gradient"/>
    <img id="slide__2-img" src="../../../assets/img/korslider3.webp"  alt="slide-2-img"/>
    <p class="slide__2-text-try">Tegyél<br/>egy próbát,<br/> kóstold meg!</p>
  </div>
</template>

<script>
export default {
  name: "SliderThird"
}
</script>

<style scoped>
.slide__2-text-try{
  position: absolute;
  text-align: center;
  line-height: 13.05vw;
  color: rgba(255, 255, 255, 1);
  font-size: 11.94vw;
  font-weight: 600;
  font-family: blackest_heavy, Arial,serif;
  white-space: pre;
  width: 73.88vw;
  height: 41.94vw;
  left: 13.05vw;
  top: 69.44vw;
}
.slide__2-ellipse-overlay-medium-right{
  position: absolute;
  -ms-transform: rotate(60.00deg);
  -webkit-transform: rotate(60.00deg);
  transform: rotate(60.00deg);
  opacity: 0.33;
  background-color: rgba(255, 199, 227, 1);
  width: 28.46vw;
  height: 29.37vw;
  left: 67.31vw;
  top: 105.95vw;
  border-radius: 50%;
}
.slide__2-ellipse-overlay-big-right{
  position: absolute;
  -ms-transform: rotate(67.00deg);
  -webkit-transform: rotate(67.00deg);
  transform: rotate(67.00deg);
  opacity: 0.65;
  width: 40.51vw;
  height: 42.33vw;
  left: 66.58vw;
  top: 97.32vw;
  background: linear-gradient(-90deg, transparent 40%, rgba(249, 249, 249, 0.8) 100%);
  border-radius: 50%;
}
#slide__2-img{
  position: absolute;
  width: 81.66vw;
  height: 72.77vw;
  left: -7.5vw;
  top: 26.38vw;
}
.slide__2-ellipse-pink-left{
  position: absolute;
  -ms-transform: rotate(-21.00deg);
  -webkit-transform: rotate(-21.00deg);
  transform: rotate(-21.00deg);
  width: 58.1vw;
  height: 58.2vw;
  left: 6.57vw;
  top: 32.26vw;
  background: linear-gradient(0deg, var(--color-magenta) 0%, #F9F9F9 100%);
  border-radius: 50%;
}
.slide__2-ellipse-overley-medium{
  position: absolute;
  background-color: rgba(255, 199, 227, 1);
  width: 273.00px;
  height: 273.00px;
  left: 10.00px;
  top: 76.00px;
  border-radius: 50%;
}
.slide__2-ellipse-overley-big{
  position: absolute;
  opacity: 0.65;
  background-color: rgba(255, 199, 227, 1);
  width: 108.33vw;
  height: 108.33vw;
  left: -11.94vw;
  top: -9.44vw;
  border-radius: 50%;
}
.slide__2-gradient{
  background: linear-gradient(0deg, transparent 35%, rgba(249, 249, 249, 0.8) 100%);
  position: absolute;
  width: 141.94vw;
  height: 82.77vw;
  left: 0;
  top: 16.38vw;
}
</style>