<template>
  <div class="slider" id="slider">
    <transition name="fade" mode="out-in">
      <div v-if="page === 0" key="first">
        <SliderFirst/>
      </div>

      <div v-if="page === 1" key="second">
        <SliderSecond/>
      </div>

      <div v-if="page === 2" key="third">
        <SliderThird/>
      </div>
    </transition>

    <b-button class="btn-ellipse slider__pagination-first" @click="toPage(0)" :disabled="page === 0"/>
    <b-button class="btn-ellipse slider__pagination-second" @click="toPage(1)" :disabled="page === 1"/>
    <b-button class="btn-ellipse slider__pagination-third" @click="toPage(2)" :disabled="page === 2"/>
    <svg id="navigation-next" viewBox="0 0 34.803 25.605" @click="nextPage(true)">
      <path d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
            transform="translate(0 -0.829)"/>
    </svg>
  </div>
</template>

<script>
import SliderFirst from "@/sections/desktop/Slider/SliderFirst";
import SliderSecond from "@/sections/desktop/Slider/SliderSecond";
import SliderThird from "@/sections/desktop/Slider/SliderThird";

export default {
  name: "Slider",
  components:{ SliderFirst, SliderSecond, SliderThird },
  data() {
    return {
      page: 0,
      timer: null
    }
  },
  methods:{
    toPage(page){
      this.page = page;
      clearInterval(this.timer);
    },
    nextPage(timer){
      this.page++;
      if(this.page === 3) this.page = 0;
      if(timer){
        clearInterval(this.timer);
      }
    }
  },
  mounted() {
    this.timer = setInterval(() => { this.nextPage(false) }, 7000);
  }
}
</script>

<style scoped>
.slider svg{
  position: absolute;
  width: 1.8vw;
  height: 1.3vw;
  left: 95.6vw;
  top: 44vw;
  cursor: pointer;
}

.slider svg path{
  fill: white;
  transition: 0.3s;
}

.slider svg:hover path{
  fill: #A91974 !important;
  transition: 0.3s;
}


.slider__pagination-first {
  position: absolute;
  left: 95.8vw;
  top: 25.5vw;
}

.slider__pagination-second {
  position: absolute;
  left: 95.8vw;
  top: 28.5vw;
}

.slider__pagination-third {
  position: absolute;
  left: 95.8vw;
  top: 31.5vw;
}

</style>