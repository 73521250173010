<template>
  <div class="contact" >
    <div class="contact__bg"/>
    <img id="contact__drip" src="../../assets/img/contact-drip.png" alt="contact-drip"/>
    <img id="contact__mail" src="../../assets/img/contact-mail.png" alt="contact-mail"/>
    <p class="contact__title">Állunk<br/>rendelkezésedre!</p>
    <b-form-input id="contact__form-name" v-model="form.name" placeholder="Név"/>
    <b-form-input id="contact__form-email" v-model="form.email" placeholder="E-mail cím"/>
    <b-form-input id="contact__form-phone" v-model="form.phone" placeholder="Telefonszám (opcionális)"/>

    <b-form-textarea
        id="contact__form-message"
        v-model="form.text"
        placeholder="Üzenet"
        rows="3"
        max-rows="6"
    />

    <b-button class="btn-contact" :disabled="isDisabled" @click="sendForm">Küldés</b-button>
    <transition name="fade" mode="out-in">
      <p class="contact__text-sent" v-if="hasSent">
        Az üzenet sikeresen elküldve,
        <br/>
        hamarosan felvesszük
        <br/>
        veled a kapcsolatot!
      </p>

      <p class="contact__text-privacy" v-else>
        *Adataidat az adatvédelmi törvényeknek megfelelően bizalmasan kezeljük, kizárólag
        kapcsolattartás céljából tároljuk, harmadik félnek nem adjuk át.
      </p>
    </transition>

    <img id="contact__rasp-down" src="../../assets/img/contact-rasp.png" alt="contact-rasp-down"/>
    <img id="contact__drip-down" src="../../assets/img/contact-drip-down.png" alt="contact-drip-down"/>
    <img id="contact__cake-down" src="../../assets/img/contact-cake-down.png" alt="contact-cake-down"/>
    <p class="contact__title-down">Málna<br/>Manufaktúra</p>
    <p class="contact__mail">info (kukac)<br/>malnamanufaktura.hu<br/><br/>+36 30 633 9033</p>
    <svg class="contact__icon-fb" viewBox="0 0 219.16 223.6" @click="openFacebook">
      <path class="cls-1" d="M121.65,7.66h-24l-.14,0h24.3Z"/>
      <path class="cls-1"
            d="M29.35,47.24C11.14,70.6,4.84,97.29,8.51,126.64c5.82,46.43,45.35,84.88,91.89,88.93,49.25,4.28,96.07-29,107.92-76.83,1.24-5,2.23-10.05,3.33-15.08v-21c-.34-1.61-.74-3.21-1-4.83-6.08-38.58-26.49-66.31-62.26-82.49-8.5-3.85-17.64-5.37-26.58-7.64H97.51c-1.55.4-3.09.89-4.66,1.16C66.76,13.52,45.53,26.48,29.35,47.24Zm67.28,36c.14-10.57,4.65-16.07,15-18a48.78,48.78,0,0,1,8.45-.55c10.36-.06,13.15,3.73,10.42,14-1.07,4-4.37,2.94-7,2.94-3.79,0-7,.38-7.57,5.18-.8,6.74.15,7.73,6.93,8.09,2.63.14,7-2.08,7.18,3.4.39,9.82-.76,11.89-6.83,12.7-7.37,1-7.37,1-7.37,8.67-.06,10.83-.17,21.67,0,32.49.07,4.05-1.33,5.7-5.49,5.62-16.09-.33-13.46,1.9-13.71-13.33v-10c0-5.83-.13-11.67,0-17.5.08-3.36-.9-5.45-4.72-5.36-3.15.08-4.45-1.76-4.35-4.76a57.18,57.18,0,0,0,0-6c-.26-3.43.92-5.46,4.74-5.31,3.24.14,4.38-1.75,4.32-4.75C96.56,88.25,96.63,85.75,96.63,83.25Z"/>
    </svg>


    <svg class="contact__icon-ins" viewBox="0 0 219.16 223.6" @click="openInstagram">
      <path class="cls-1"
            d="M152.51,133.79c.46-14.15.46-28.34,0-42.49-.4-12-6.42-18-18.25-19.2A227.43,227.43,0,0,0,90.4,72c-13.13,1.14-19.16,7.27-19.59,20.47-.19,6.82,0,13.66,0,20.49,0,7-.27,14,0,21,.44,11.62,6.25,17.9,17.72,19.11a225.11,225.11,0,0,0,44.88.08C146,152,152.1,146.17,152.51,133.79Zm-41,5.29A26.56,26.56,0,0,1,85,112.79C84.7,98.37,97,86.16,111.65,86.1H112a26.1,26.1,0,0,1,25.84,26.36C137.91,127.65,126.64,139.08,111.52,139.08ZM138.43,91.6c-3.71,0-5.75-2.43-5.71-6.09s2.06-5.46,5.65-5.46,5.8,2,6.21,5.55C144.28,89.6,142.14,91.63,138.43,91.6Z"/>
      <path class="cls-1"
            d="M111.47,97.16c-8.5,0-14.81,6.57-14.82,15.38,0,9.13,6.33,15.6,15.18,15.49,8.61-.11,14.92-6.45,14.94-15.31A15.22,15.22,0,0,0,111.47,97.16Z"/>
      <path class="cls-1" d="M97.71,216.07h23l.37-.12H97.17Z"/>
      <path class="cls-1"
            d="M208.24,139.61c1.33-5.13,2.32-10.36,3.47-15.54v-21a26.26,26.26,0,0,1-.89-3.35C207,69.8,193.34,45.61,169.05,27.52,126.9-3.84,65.46,2.05,29.75,47.16,10.89,71,4.07,98.5,8.87,128.41c5.47,34,23.7,59.48,53.9,76.24,10.74,6,22.53,8.77,34.4,11.3h23.91a27.51,27.51,0,0,1,3-.8C164.24,209.22,198,178.92,208.24,139.61ZM85.4,164.16c-15.56-2-25-12.72-25.69-28.31s-.67-30.91,0-46.35C60.39,73.24,70.61,62.39,86.8,61c16.77-1.47,33.57-1.83,50.32.09s26,12.64,26.56,29.55c.19,7.47,0,15,0,22.47l0,.06c0,6.16.05,12.32,0,18.48-.18,20.41-9.27,31.43-29.49,33C118,165.87,101.62,166.22,85.4,164.16Z"/>
    </svg>

    <div class="mapouter">
      <img src="../../assets/img/8.jpg" />
<!--      <div class="gmap_canvas">-->
<!--        <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"-->
<!--                src="https://maps.google.com/maps?width=792&amp;height=630&amp;hl=en&amp;q=Székesfehérvár, Milleniumi emlékmű&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">-->
<!--        </iframe>-->
<!--      </div>-->
    </div>
  </div>

</template>

<script>
export default {
  name: "Contact",
  computed: {
    isDisabled: function () {
      return !this.form.name || !this.form.email || !this.form.text;
    },
  },
  methods: {
    async sendForm() {
      let method = 'post';
      let route = '/sendemail';
      let data = this.form;
      let result = await this.apiRequest(method, route, data);
      if (result) {
        this.hasSent = true;
        setTimeout(() => {
          this.hasSent = false
        }, 10000);
      }
    },
    openFacebook() {
      window.open('https://www.facebook.com/malnamanufaktura/', '_blank').focus();
    },
    openInstagram() {
      window.open('https://www.instagram.com/malnamanufaktura/', '_blank').focus();
    },
  },

  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        text: null,
      },
      hasSent: false
    }
  },
}
</script>

<style scoped>

.mapouter img{
  /*width: 100vw;*/
  height: 67vw;
}
.contact__icon-ins {
  position: absolute;
  cursor: pointer;
  width: 17.5vw;
  height: 16.94vw;
  left: 32.5vw;
  top: 1556.38vw;
}

.contact__icon-fb {
  position: absolute;
  cursor: pointer;
  width: 17.5vw;
  height: 16.94vw;
  left: 12.77vw;
  top: 1556.38vw;
}

.cls-1 {
  fill: white;
  transition: 0.3s;
}
.contact__mail{
  position: absolute;
  text-align: left;
  line-height: 7.77vw;
  color: rgba(255, 255, 255, 1);
  font-size: 7.22vw;
  font-weight: 400;
  font-family: blackest_regular, Arial,serif;
  white-space: pre;
  width: 75vw;
  height: 32.77vw;
  left: 12.5vw;
  top: 1515.27vw;
}
.contact__title-down{
  position: absolute;
  text-align: left;
  line-height: 11.94vw;
  color: rgba(255, 255, 255, 1);
  font-size: 11.66vw;
  font-weight: 600;
  font-family: blackest_heavy, Arial,serif;
  white-space: pre;
  width: 75.83vw;
  height: 27.5vw;
  left: 12.22vw;
  top: 1480.13vw;
}
#contact__cake-down{
  position: absolute;
  width: 35vw;
  height: 36.38vw;
  left: 59.16vw;
  top: 1540.83vw;
}
#contact__drip-down{
  position: absolute;
  -ms-transform: rotate(-90.00deg);
  -webkit-transform: rotate(-90.00deg);
  transform: rotate(-90.00deg);
  width: 104.76vw;
  height: 86.54vw;
  left: 40.83vw;
  top: 1461.31vw;
}
#contact__rasp-down{
  position: absolute;
  -ms-transform: rotate(49.00deg);
  -webkit-transform: rotate(49.00deg);
  transform: rotate(49.00deg);
  width: 31.66vw;
  height: 33.03vw;
  left: 2.29vw;
  top: 1458.33vw;
}
.mapouter {
  position: absolute;
  text-align: right;
  width: 100vw;
  height: 67vw;
  left: -6vw;
  top: 1388.88vw;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100vw;
  height: 67vw;
}

.gmap_iframe {
  width: 100vw !important;
  height: 67vw !important;
}

.contact__text-privacy {
  position: absolute;
  color: white;
  font-size: 5vw;
  font-family: blackest_regular, Arial, serif;
  left: 11.2vw;
  width: 78vw;
  top: 1349.44vw;
  text-align: center;
  line-height: 1.2;
}

.contact__text-sent {
  position: absolute;
  color: white;
  font-size: 5.55vw;
  font-family: blackest_regular, Arial, serif;
  left: 13.05vw;
  top: 1349.44vw;
  text-align: center;
  line-height: 1.2;
}

.btn-contact {
  font-size: 6.38vw !important;
  position: absolute;
  width: 50vw;
  height: 18.33vw;
  left: 25.55vw;
  top: 1321.66vw;
}

#contact__form-message {
  position: absolute;
  width: 77.22vw;
  height: 80.83vw !important;
  left: 11.38vw;
  top: 1224.72vw;
}

#contact__form-phone {
  position: absolute;
  width: 77.22vw;
  height: 12.77vw;
  left: 11.38vw;
  top: 1207.5vw;
}

#contact__form-email {
  position: absolute;
  width: 77.22vw;
  height: 12.77vw;
  left: 11.38vw;
  top: 1190.27vw;
}

#contact__form-name {
  position: absolute;
  width: 77.22vw;
  height: 12.77vw;
  left: 11.38vw;
  top: 1173.05vw;
}

.contact__title {
  position: absolute;
  text-align: center;
  line-height: 10.83vw;
  color: rgba(255, 255, 255, 1);
  font-size: 9.16vw;
  font-weight: 600;
  font-family: blackest_heavy, Arial, serif;
  white-space: pre;
  width: 75vw;
  height: 23.05vw;
  left: 12.5vw;
  top: 1139.3vw;
}

#contact__mail {
  position: absolute;
  width: 56.94vw;
  height: 45.98vw;
  left: -11.52vw;
  top: 1112.22vw;
}

#contact__drip {
  position: absolute;
  width: 211.11vw;
  height: 83.33vw;
  left: -30vw;
  top: 1104.72vw;
}

.contact__bg {
  position: absolute;
  width: 100vw;
  height: 488.05vw;
  left: 0;
  top: 1107.77vw;
  background: linear-gradient(180deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
}
</style>