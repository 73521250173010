<template>
  <div>
    <div class="slide__0-ellipse-left-overlay"/>
    <div class="slide__0-ellipse-right-overlay"/>
    <div class="slide__0-ellipse-left-pink ellipse-bg-gradient"/>
    <div class="slide__0-ellipse-right-pink ellipse-bg-gradient"/>
    <img id="slide__0-cake" src="../../../assets/img/slider1torta.webp" alt="slider-0-cake"/>
    <p class="slide__0-text-welcome">Üdvözlünk!</p>
    <p class="slide__0-text-title">Lásd <br/>rózsaszínben <br>a világot!</p>
  </div>
</template>

<script>
export default {
  name: "SliderFirst"
}
</script>

<style scoped>

.slide__0-text-title{
  position: absolute;
  text-align: left;
  line-height: 13.05vw;
  color: white;
  font-size: 13.05vw;
  font-weight: 600;
  font-family: blackest_heavy, Arial,serif;
  white-space: pre;
  left: 8.33vw;
  top: 69.02vw;
}
.slide__0-text-welcome{
  position: absolute;
  text-align: left;
  color: white;
  font-size: 8.61vw;
  font-weight: 400;
  font-family: blackest_regular, Arial,serif;
  white-space: pre;
  left: 8.33vw;
  top: 53.88vw;
}
#slide__0-cake{
  position: absolute;
  opacity: 0.84;
  width: 144.44vw;
  height: 138.61vw;
  left: -56.94vw;
  top: -13.88vw;
}

.slide__0-ellipse-right-overlay{
  position: absolute;
  -ms-transform: rotate(-4.00deg);
  -webkit-transform: rotate(-4.00deg);
  transform: rotate(-4.00deg);
  opacity: 0.15;
  background-color: rgba(255, 255, 255, 1);
  width: 38.58vw;
  height: 38.58vw;
  left: 71.61vw;
  top: 118.425vw;
  border-radius: 50%;
}

.slide__0-ellipse-right-pink{
  position: absolute;
  width: 29.475vw;
  height: 29.475vw;
  left: 74.7vw;
  top: 121.41vw;
  border-radius: 50%;
}

.slide__0-ellipse-left-overlay{
  position: absolute;
  -ms-transform: rotate(-4.00deg);
  -webkit-transform: rotate(-4.00deg);
  transform: rotate(-4.00deg);
  opacity: 0.15;
  background-color: rgba(255, 255, 255, 1);
  width: 193.68vw;
  height: 193.68vw;
  left: -81.8vw;
  top: -68.7vw;
  border-radius: 50%;
}

.slide__0-ellipse-left-pink{
  position: absolute;
  -ms-transform: rotate(32.00deg);
  -webkit-transform: rotate(32.00deg);
  transform: rotate(32.00deg);
  -ms-transform-origin: center;
  width: 150.67vw;
  height: 150.67vw;
  left: -60.82vw;
  top: -31.22vw;
  border-radius: 50%;
}
</style>