<template>
  <div class="drips">
    <div data-aos="fade-in" data-aos-delay="600" class="drips__ellipse"/>
    <img data-aos="fade-in" data-aos-delay="600" id="drips__img-rasp" src="../../assets/img/malnalila.webp" alt="drips-img"/>
    <video data-aos="fade-bottom" id="drips__first" src="../../assets/anims/sodrofa.webm" autoplay muted/>
    <video data-aos="fade-bottom" data-aos-delay="200" id="drips__second" src="../../assets/anims/torta.webm" autoplay muted/>
    <video data-aos="fade-bottom" data-aos-delay="400" id="drips__third" src="../../assets/anims/sziv.webm" autoplay muted/>

    <p data-aos="fade-bottom" class="drips__first-number">5</p>
    <p data-aos="fade-bottom" class="drips__first-text">év tapasztalt</p>

    <p data-aos="fade-bottom" data-aos-delay="200" class="drips__second-number">750</p>
    <p data-aos="fade-bottom" data-aos-delay="200" class="drips__second-text">csodás torta</p>

    <p data-aos="fade-bottom" data-aos-delay="400" class="drips__third-number">150</p>
    <p data-aos="fade-bottom" data-aos-delay="400" class="drips__third-text">édesebb<br>rendezvény</p>
  </div>
</template>

<script>
export default {
name: "Drips"
}
</script>

<style scoped>
.drips__third-text{
  position: absolute;
  text-align: center;
  color: white;
  font-size: 2.18vw;
  font-family: blackest_regular, serif;
  width: 21.66vw;
  height: 4.11vw;
  left: 64.6vw;
  margin-top: 0.6vw;
  line-height: 2.2vw;
  top: 146.92vw;
}

.drips__third-number{
  position: absolute;
  text-align: right;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  left: 70.7vw;
  top: 140.57vw;
}
.drips__second-text{
  position: absolute;
  text-align: center;
  color: white;
  font-size: 2.18vw;
  font-family: blackest_regular, serif;
  width: 21.66vw;
  height: 4.11vw;
  left: 39.1vw;
  top: 146.92vw;
}

.drips__second-number{
  position: absolute;
  text-align: right;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  left: 44.75vw;
  top: 140.57vw;
}

.drips__first-text{
  position: absolute;
  text-align: center;
  color: white;
  font-size: 2.18vw;
  font-family: blackest_regular, serif;
  width: 21.66vw;
  height: 4.11vw;
  left: 14.37vw;
  top: 146.92vw;
}

.drips__first-number{
  position: absolute;
  text-align: right;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  left: 22.91vw;
  top: 140.57vw;
}

.drips__ellipse{
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 71vw;
  height: 71vw;
  left: -26.19vw;
  top: 137.6vw;

}
#drips__img-rasp{
  position: absolute;
  width: 17.7vw;
  height: 19.47vw;
  left: 53.17vw;
  top: 116.09vw;
}
#drips__third{
  position: absolute;
  width: 23.3vw;
  height: 29.1vw;
  left: 63.7vw;
  top: 133.9vw;
}

#drips__second{
  position: absolute;
  width: 23.3vw;
  height: 29.1vw;
  left: 38.3vw;
  top: 133.9vw;
}

#drips__first{
  position: absolute;
  width: 23.3vw;
  height: 29.1vw;
  left: 12.9vw;
  top: 133.9vw;
}
</style>