<template>
  <div class="gallery-cookie">
    <transition name="fade" mode="out-in">
      <div v-if="!isOpened" key="gallery-cookie-closed">
        <div class="gallery-cookie__bg-closed"/>
        <img id="gallery-cookie__img" src="../../../assets/img/gallery-cookie-closed.webp" alt="gallery-cookie"/>
        <video id="gallery-cookie__anim" src="../../../assets/anims/gallery-cookie.webm" autoplay muted/>
        <p class="gallery-cookie__text" @click="toggleOpen">
          Aprósütemény

          <svg viewBox="0 0 137.79 137.79">
            <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
            <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
            <path class="cls-3" d="M68,67.86Z"/>
            <path class="cls-4"
                  d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
          </svg>

        </p>

      </div>
      <div v-else key="gallery-cookie-opened">
        <div class="gallery-cookie__bg-opened"/>

        <svg class="svg__close" viewBox="0 0 98 98" @click="toggleOpen">
          <path class="path-close"
                d="M59,49,78.64,29.3a6.5,6.5,0,1,0-9.19-9.19L49.79,39.77,30.13,20.11a6.5,6.5,0,0,0-9.19,9.19L40.6,49,20.94,68.62a6.5,6.5,0,0,0,9.19,9.2L49.79,58.16,69.45,77.82a6.5,6.5,0,1,0,9.19-9.2Z"/>
        </svg>

        <p class="gallery-cookie__text-opened">Aprósütemény</p>
        <img id="gallery-cookie__img-opened" src="../../../assets/img/galleries/cookie/opened.webp" alt="gallery-cookie-opened"/>
        <b-button class="btn-gallery btn-sweet" @click="changeCategory('sweet')" :disabled="category === 'sweet'">
          édes
        </b-button>
        <b-button class="btn-gallery btn-salty" @click="changeCategory('salty')" :disabled="category === 'salty'">
          sós
        </b-button>

        <b-button class="btn-ellipse btn-ellipse-first" @click="toPage(0)" :disabled="page === 0"/>
        <b-button class="btn-ellipse btn-ellipse-second" @click="toPage(1)" :disabled="page === 1"/>


        <transition name="fade" mode="out-in">
          <div v-if="category === 'sweet'" key="ellipse-sweet">
            <b-button class="btn-ellipse btn-ellipse-third" @click="toPage(2)" :disabled="page === 2"/>
          </div>
        </transition>

        <svg class="arrow-next" viewBox="0 0 34.803 25.605" @click="nextPage">
          <path
              d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
              transform="translate(0 -0.829)"/>
        </svg>

        <transition name="fade" mode="out-in">
          <div v-if="category === 'sweet'" key="images-sweet">
            <transition name="fade" mode="out-in">
              <div :key="page">
                <ImageBlock
                    :id="imagesSweet[page * 3].id"
                    :src="imagesSweet[page * 3].src"
                    :name="imagesSweet[page * 3].name"
                    :image-array="imagesSweet"
                    :index="page * 3"
                    topImage="227.86vw"
                    topMiddle="15.5vw"
                    variant="big"
                    container-pos-left="0"
                />
                <ImageBlock
                    :id="imagesSweet[page * 3 + 1].id"
                    :src="imagesSweet[page * 3 + 1].src"
                    :name="imagesSweet[page * 3 + 1].name"
                    :image-array="imagesSweet"
                    :index="page * 3 + 1"
                    topImage="247.15vw"
                    topMiddle="6.3vw"
                    variant="medium"
                    container-pos-left="49.94vw"
                />

                <ImageBlock
                    :id="imagesSweet[page * 3 + 2].id"
                    :src="imagesSweet[page * 3 + 2].src"
                    :name="imagesSweet[page * 3 + 2].name"
                    :image-array="imagesSweet"
                    :index="page * 3 + 2"
                    topImage="247.15vw"
                    topMiddle="6.3vw"
                    variant="medium"
                    container-pos-left="75vw"
                />
              </div>
            </transition>
          </div>
          <div v-else key="images-salty">
            <transition name="fade" mode="out-in">
              <div :key="page">
                <ImageBlock
                    :id="imagesSalty[page * 3].id"
                    :src="imagesSalty[page * 3].src"
                    :name="imagesSalty[page * 3].name"
                    :image-array="imagesSalty"
                    :index="page * 3"
                    topImage="227.86vw"
                    topMiddle="15.5vw"
                    variant="big"
                    container-pos-left="0"
                />
                <ImageBlock
                    :id="imagesSalty[page * 3 + 1].id"
                    :src="imagesSalty[page * 3 + 1].src"
                    :name="imagesSalty[page * 3 + 1].name"
                    :image-array="imagesSalty"
                    :index="page * 3 + 1"
                    topImage="247.15vw"
                    topMiddle="6.3vw"
                    variant="medium"
                    container-pos-left="49.94vw"
                />

                <ImageBlock
                    :id="imagesSalty[page * 3 + 2].id"
                    :src="imagesSalty[page * 3 + 2].src"
                    :name="imagesSalty[page * 3 + 2].name"
                    :image-array="imagesSalty"
                    :index="page * 3 + 2"
                    topImage="247.15vw"
                    topMiddle="6.3vw"
                    variant="medium"
                    container-pos-left="75vw"
                />
              </div>
            </transition>
          </div>
        </transition>


      </div>
    </transition>
  </div>
</template>

<script>
import ImageBlock from "@/sections/desktop/Galleries/blocks/ImageBlock";

export default {
  name: "Cookie",
  components: {
    ImageBlock,
  },
  data() {
    return {
      page: 0,
      isOpened: false,
      category: 'sweet',
      imagesSweet: [
        {
          id: 'gallery-img-sweet-0',
          name: 'Mandulás színes linzer',
          src: require('@/assets/img/galleries/cookie/first-0.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/0.webp')
        },
        {
          id: 'gallery-img-sweet-1',
          name: 'Diócska',
          src: require('@/assets/img/galleries/cookie/first-1.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/1.webp')
        },
        {
          id: 'gallery-img-sweet-2',
          name: 'Gyümölcskosárka',
          src: require('@/assets/img/galleries/cookie/first-2.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/2.webp')
        },
        {
          id: 'gallery-img-sweet-3',
          name: 'Meggyes pohárkrém',
          src: require('@/assets/img/galleries/cookie/second-0.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/3.webp')
        },

        {
          id: 'gallery-img-sweet-4',
          name: 'Macaron',
          src: require('@/assets/img/galleries/cookie/second-1.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/4.webp')
        },

        {
          id: 'gallery-img-sweet-5',
          name: 'Kútban kelt kifli',
          src: require('@/assets/img/galleries/cookie/second-2.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/5.webp')
        },

        {
          id: 'gallery-img-sweet-6',
          name: 'Bejgli',
          src: require('@/assets/img/galleries/cookie/third-0.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/6.webp')
        },
        {
          id: 'gallery-img-sweet-7',
          name: 'Kókuszgolyó',
          src: require('@/assets/img/galleries/cookie/third-1.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/7.webp')
        },
        {
          id: 'gallery-img-sweet-8',
          name: 'Hólabda',
          src: require('@/assets/img/galleries/cookie/third-2.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery/8.webp')
        },
      ],
      imagesSalty: [
        {
          id: 'gallery-img-salty-0',
          name: 'Sajtos rúd',
          src: require('@/assets/img/galleries/cookie/salty-0.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/0.webp')
        },
        {
          id: 'gallery-img-salty-1',
          name: 'Sajtos pogácsa',
          src: require('@/assets/img/galleries/cookie/salty-1.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/1.webp')
        },
        {
          id: 'gallery-img-salty-2',
          name: 'Croissant',
          src: require('@/assets/img/galleries/cookie/salty-2.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/2.webp')
        },
        {
          id: 'gallery-img-salty-3',
          name: 'Kiflicske',
          src: require('@/assets/img/galleries/cookie/salty-3.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/3.webp')
        },
        {
          id: 'gallery-img-salty-4',
          name: 'Pizza',
          src: require('@/assets/img/galleries/cookie/salty-4.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/4.webp')
        },
        {
          id: 'gallery-img-salty-5',
          name: 'Töltött pogácsa',
          src: require('@/assets/img/galleries/cookie/salty-5.webp'),
          srcGallery: require('@/assets/img/galleries/cookie/gallery-salty/5.webp')
        }
      ]
    }
  },
  watch: {
    page() {
    }
  },
  methods: {
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 3 && this.category === 'sweet') this.page = 0;
      if (this.page === 2 && this.category === 'salty') this.page = 0;
      // let images = this.imagesSweet;
      // console.log('sliced', images.slice(this.page * 3, this.page * 3 + 3));
      // this.activeArray =
    },
    changeCategory(category) {
      this.category = category;
      this.page = 0;
    },
    toggleOpen() {
      this.page = 0;
      this.category = 'sweet';
      this.isOpened = !this.isOpened;
    }
  }
}
</script>

<style scoped>

#gallery-cookie__img-opened{
  position: absolute;
  width: 110.15vw;
  height: 19.63vw;
  left: 83.28vw;
  top: 227.86vw;
}
.gallery-cookie .arrow-next {
  position: absolute;


  left: 75.19vw;
  top: 230.66vw;

  width: 1.8vw;
  height: 1.3vw;
  cursor: pointer;
  /*z-index: 2;*/
}

.gallery-cookie .arrow-next path {
  fill: white;
  transition: 0.3s;
}

.gallery-cookie .arrow-next:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}

.btn-ellipse-first {
  position: absolute;
  left: 62.91vw;
  top: 230.78vw;
}

.btn-ellipse-second {
  position: absolute;
  left: 66.09vw;
  top: 230.78vw;
}

.btn-ellipse-third {
  position: absolute;
  left: 69.27vw;
  top: 230.78vw;
}


.gallery-cookie__text-opened {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 4.06vw;
  font-family: blackest_heavy, serif;
  left: 55.93vw;
  top: 234.53vw;

}

.btn-salty {
  position: absolute;
  left: 65.83vw;
  top: 241.56vw;
}

.btn-sweet {
  position: absolute;
  left: 55.93vw;
  top: 241.56vw;
}


.svg__close {
  cursor: pointer;
  position: absolute;
  left: 51.22vw;
  top: 229.3vw;
  width: 4.32vw;
  height: 4.32vw;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.path-close {
  fill: white;
  transition: 0.3s;
}

.gallery-cookie__bg-opened {
  position: absolute;
  width: 100vw;
  height: 41.19vw;
  left: 0;
  top: 227.86vw;
  background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 55%);
}

.cls-1 {
  fill: #fc0584;
}

.cls-1, .cls-2, .cls-3 {
  stroke: #fc0584;
}

.cls-2, .cls-3 {
  fill: none;
}

.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 13px;
}

.cls-4 {
  fill: #fff;
  transition: 0.3s;
}

.gallery-cookie__text:hover .cls-4 {
  fill: #A91974;
  transition: 0.3s;
}

.gallery-cookie__text svg {
  position: absolute;
  left: -9.85vw;
  width: 6vw;
  top: 1.1vw;
}

.gallery-cookie__text {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  width: 40.93vw;
  height: 7.55vw;
  left: 42.6vw;
  top: 252.02vw;
  cursor: pointer;
  transition: 0.3s;
}

.gallery-cookie__text:hover {
  color: var(--color-magenta);
}


#gallery-cookie__anim {
  position: absolute;
  width: 88.31vw;
  height: 23.03vw;
  left: 24.04vw;
  top: 226.6vw;
}

#gallery-cookie__img {
  position: absolute;
  width: 45.1vw;
  height: 41.19vw;
  left: -9.32vw;
  top: 227.86vw;
}

.gallery-cookie__bg-closed {
  position: absolute;
  background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 67.41vw;
  height: 33.78vw;
  left: 32.5vw;
  top: 231.75vw;
}
</style>