<template>
  <div class="gallery-birthday">
    <transition name="fade" mode="out-in">
      <div v-if="!isOpened" key="gallery-birthday-closed">
        <div class="gallery-birthday__bg-closed"/>
        <img id="gallery-birthday__img" src="../../../assets/img/galleries/birthday/closed.webp" alt="gallery-cookie"/>
        <video id="gallery-birthday__anim" src="../../../assets/anims/gallery-birthday.webm" autoplay muted/>

        <p class="gallery-birthday__text" @click="toggleOpen">
          Szülinapi torta

          <svg viewBox="0 0 137.79 137.79">
            <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
            <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
            <path class="cls-3" d="M68,67.86Z"/>
            <path class="cls-4"
                  d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
          </svg>

        </p>
      </div>

      <div v-else key="gallery-birthday-opened">
        <div class="gallery-birthday__bg-opened"/>
        <svg class="svg__close" viewBox="0 0 98 98" @click="toggleOpen">
          <path class="path-close"
                d="M59,49,78.64,29.3a6.5,6.5,0,1,0-9.19-9.19L49.79,39.77,30.13,20.11a6.5,6.5,0,0,0-9.19,9.19L40.6,49,20.94,68.62a6.5,6.5,0,0,0,9.19,9.2L49.79,58.16,69.45,77.82a6.5,6.5,0,1,0,9.19-9.2Z"/>
        </svg>

        <p class="gallery-birthday__text-opened">Szülinapi<br/>torta</p>
        <img id="gallery-birthday__img-opened" src="../../../assets/img/galleries/birthday/opened-icon.webp" alt="gallery-opened"/>
        <b-button class="btn-ellipse btn-ellipse-first" @click="toPage(0)" :disabled="page === 0"/>
        <b-button class="btn-ellipse btn-ellipse-second" @click="toPage(1)" :disabled="page === 1"/>
        <b-button class="btn-ellipse btn-ellipse-third" @click="toPage(2)" :disabled="page === 2"/>

        <svg class="arrow-next" viewBox="0 0 34.803 25.605" @click="nextPage">
          <path
              d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
              transform="translate(0 -0.829)"/>
        </svg>

        <transition name="fade" mode="out-in">
          <div :key="page">
            <ImageBlock
                :id="images[page * 3].id"
                :src="images[page * 3].src"
                :name="images[page * 3].name"
                :image-array="images"
                :index="page * 3"
                topImage="289.94vw"
                topMiddle="6.3vw"
                variant="medium"
                container-pos-left="0"
                image-height="21.2vw"
            />
            <ImageBlock
                :id="images[page * 3 + 1].id"
                :src="images[page * 3 + 1].src"
                :name="images[page * 3 + 1].name"
                :image-array="images"
                :index="page * 3 + 1"
                topImage=" 289.94vw"
                topMiddle="6.3vw"
                variant="medium"
                container-pos-left="25vw"
                image-height="21.2vw"
            />
            <ImageBlock
                :id="images[page * 3 + 2].id"
                :src="images[page * 3 + 2].src"
                :name="images[page * 3 + 2].name"
                :image-array="images"
                :index="page * 3 + 2"
                topImage="269.92vw"
                topMiddle="15.5vw"
                variant="big"
                container-pos-left="50vw"
            />


          </div>
        </transition>

      </div>
    </transition>
  </div>
</template>

<script>
import ImageBlock from "@/sections/desktop/Galleries/blocks/ImageBlock";

export default {
  name: "GalleryBirthdayCake",
  components: {
    ImageBlock,
  },
  data() {
    return {
      page: 0,
      isOpened: false,
      images: [
        {
          id: 'gallery-img-birthday-0',
          name: 'Citrom torta',
          src: require('@/assets/img/galleries/birthday/first-0.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/first-0-opened.webp')
        },
        {
          id: 'gallery-img-birthday-1',
          name: 'Dobos torta',
          src: require('@/assets/img/galleries/birthday/first-1.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/first-1-opened.webp')
        },
        {
          id: 'gallery-img-birthday-2',
          name: 'Csoki torta',
          src: require('@/assets/img/galleries/birthday/szulinapi-01.png'),
          srcGallery: require('@/assets/img/galleries/birthday/first-2-opened.webp')
        },
        {
          id: 'gallery-img-birthday-3',
          name: 'Oreo torta',
          src: require('@/assets/img/galleries/birthday/second-0.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/second-0-opened.webp')
        },
        {
          id: 'gallery-img-birthday-4',
          name: 'Málnás-csokis macaronos',
          src: require('@/assets/img/galleries/birthday/second-1.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/second-1-opened.webp')
        },
        {
          id: 'gallery-img-birthday-5',
          name: 'Oroszkrém torta',
          src: require('@/assets/img/galleries/birthday/second-2.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/second-2-opened.webp')
        },
        {
          id: 'gallery-img-birthday-6',
          name: 'Répatorta',
          src: require('@/assets/img/galleries/birthday/third-0.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/third-0-opened.webp')
        },
        {
          id: 'gallery-img-birthday-7',
          name: 'Erdei gyümölcsös',
          src: require('@/assets/img/galleries/birthday/third-1.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/third-1-opened.webp')
        },
        {
          id: 'gallery-img-birthday-8',
          name: 'Sajt torta',
          src: require('@/assets/img/galleries/birthday/third-2.webp'),
          srcGallery: require('@/assets/img/galleries/birthday/third-2-opened.webp')
        },
      ]
    }
  },
  watch: {
    page() {
    }
  },
  methods: {
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 3) this.page = 0;
    },
    toggleOpen() {
      this.page = 0;
      this.isOpened = !this.isOpened;
    }
  }
}
</script>

<style scoped>
.gallery-birthday .arrow-next {
  position: absolute;


  left: 23.25vw;
  top: 272.53vw;

  width: 1.8vw;
  height: 1.3vw;
  cursor: pointer;
  /*z-index: 2;*/
}

.gallery-birthday .arrow-next path {
  fill: white;
  transition: 0.3s;
}

.gallery-birthday .arrow-next:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}
.btn-ellipse-first {
  position: absolute;
  left: 32.43vw;
  top: 272.81vw;
}

.btn-ellipse-second {
  position: absolute;
  left: 35.61vw;
  top: 272.81vw;
}

.btn-ellipse-third {
  position: absolute;
  left: 38.78vw;
  top: 272.81vw;
}

#gallery-birthday__img-opened{
  position: absolute;
  width: 112.34vw;
  height: 20vw;
  left: -91.56vw;
  top: 269.94vw;
}
.gallery-birthday__text-opened{
  position: absolute;
  text-align: right;
  line-height: 4.375vw;
  color: white;
  font-size: 4vw;
  font-family: blackest_heavy, serif;
  width: 18.69vw;
  height: 9.79vw;
  left: 25.36vw;
  top: 277.18vw;
  transition: 0.3s;
}
.svg__close {
  cursor: pointer;
  position: absolute;
  left: 44.62vw;
  top: 272.05vw;
  width: 4.32vw;
  height: 4.32vw;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.path-close {
  fill: white;
  transition: 0.3s;
}
.gallery-birthday__bg-opened {
  position: absolute;
  width: 100vw;
  height: 41.19vw;
  left: 0;
  top: 269.89vw;
  background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 55%);
}

.cls-1 {
  fill: #fc0584;
}

.cls-1, .cls-2, .cls-3 {
  stroke: #fc0584;
}

.cls-2, .cls-3 {
  fill: none;
}

.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 13px;
}

.cls-4 {
  fill: #fff;
  transition: 0.3s;
}

.gallery-birthday__text:hover .cls-4 {
  fill: #A91974;
  transition: 0.3s;
}

.gallery-birthday__text svg {
  position: absolute;
  left: 47.2vw;
  width: 6vw;
  top: 1.1vw;
}

.gallery-birthday__text {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  cursor: pointer;
  white-space: pre;
  width: 42.5vw;
  height: 7.55vw;
  left: 12.81vw;
  top: 293.5vw;
  transition: 0.3s;
}

.gallery-birthday__text:hover {
  color: var(--color-magenta);
  transition: 0.3s;
}


#gallery-birthday__anim {
  position: absolute;
  width: 88.31vw;
  height: 23.03vw;
  left: -13.7vw;
  top: 269.2vw;
}

#gallery-birthday__img {
  position: absolute;
  width: 43.75vw;
  height: 40.07vw;
  left: 62.95vw;
  top: 269.93vw;

}


.gallery-birthday__bg-closed {
  position: absolute;
  background: linear-gradient(90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 67.41vw;
  height: 33.78vw;
  left: -4.24vw;
  top: 273.48vw;
}
</style>