<template>
  <div class="newsletter">
    <img id="newsletter__bg" src="../../assets/img/newsletter-bg.webp" alt="newsletter-bg"/>
    <img id="newsletter__cake" src="../../assets/img/newsletter-cake.webp" alt="newsletter-cake"/>

    <p class="newsletter__text-large">
      Online
      <br/>
      tortarendelés
      <br/>
      hamarosan!
    </p>

    <p class="newsletter__text-tiny">
      Ha nem szeretnél lemaradni a
      <br/>
      legfrissebb híreinkről, ajánlatainkról
      <br/>
      iratkozz fel a hírlevélre!
    </p>

    <b-form-input id="newsletter__form-email" v-model="form.email" placeholder="E-mail cím"/>
    <b-button class="btn-newsletter">Feliratkozás</b-button>
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {
      form: {
        email: null,
      }
    }
  },
}
</script>

<style scoped>
.btn-newsletter{
  position: absolute;
  left: 48.75vw;
  top:  524.01vw;
}

#newsletter__form-email{
  position: absolute;
  width: 30.05vw;
  height: 4.27vw;
  left: 48.64vw;
  top: 516.61vw;
}

.newsletter__text-tiny {
  position: absolute;
  line-height: 1.875vw;
  color: white;
  font-size: 1.45vw;
  font-family: blackest_regular, serif;
  left: 48.69vw;
  top: 509.29vw;
}

.newsletter__text-large {
  position: absolute;
  text-align: left;
  line-height: 5.15vw;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  left: 48.69vw;
  top: 492.18vw;
}

#newsletter__cake {
  position: absolute;
  width: 39.89vw;
  height: 39.21vw;
  left: 11.45vw;
  top: 485.98vw;
}

#newsletter__bg {
  position: absolute;
  width: 100vw;
  height: 62.5vw;
  left: 0;
  top: 474.21vw;
}
</style>