<template>
  <div>
    <GalleryCookie/>
    <GalleryBirthdayCake/>
    <GalleryWeddingCake/>
    <GalleryEvents/>
  </div>
</template>

<script>
import GalleryCookie from "@/sections/desktop/Galleries/GalleryCookie";
import GalleryBirthdayCake from "@/sections/desktop/Galleries/GalleryBirthdayCake";
import GalleryWeddingCake from "@/sections/desktop/Galleries/GalleryWeddingCake";
import GalleryEvents from "@/sections/desktop/Galleries/GalleryEvents";

export default {
  name: "Galleries",
  components:{
    GalleryCookie,
    GalleryBirthdayCake,
    GalleryWeddingCake,
    GalleryEvents
  }
}
</script>

<style scoped>

</style>