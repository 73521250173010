<template>
  <div class="footer-bg">
    <svg v-scroll-to="{ el: '#slider', offset: -100 }" class="footer__svg-arrow" viewBox="0 0 43 58">
      <path
          d="M40.66,20.62,22.84,2.8h0a2.11,2.11,0,0,0-.23-.19,2.17,2.17,0,0,0-1.35-.49h-.12l-.19,0a2.48,2.48,0,0,0-.27,0l-.16.05-.28.09-.15.09a2,2,0,0,0-.25.14l-.14.12-.21.18,0,0L1.4,22.48a2.35,2.35,0,0,0,.14,3.31,2.31,2.31,0,0,0,1.58.62,2.33,2.33,0,0,0,1.73-.75l14-15.23L19,55a2.35,2.35,0,0,0,2.34,2.34h0A2.34,2.34,0,0,0,23.72,55l-.17-44.82,13.8,13.8a2.34,2.34,0,0,0,3.31-3.31Z"/>
    </svg>

    <p class="footer__text-rasp">© Málna Manufaktúra {{ year }}.</p>
    <p class="footer__text-rights">Minden jog fenntartva!</p>

<!--    <svg class="footer__icon-y2k" viewBox="0 0 91.3 32.5" @click="openY2K">-->
<!--      <path class="cls-1-logo"-->
<!--            d="M88.39,28.64l-.09-.11L86.75,27h0l-7.3-7.25a1.08,1.08,0,0,1-.18-.24h0a.32.32,0,0,1,0-.14.76.76,0,0,1,0-.21h0a1.3,1.3,0,0,1,.08-.17h0a1.58,1.58,0,0,1,.12-.15l4.77-4.72,1.18-1.18L88,10.39a1.71,1.71,0,0,0,.49-1.23A1.67,1.67,0,0,0,88.1,8a1.63,1.63,0,0,0-1.25-.41H79a4.66,4.66,0,0,0-1.77.28,5.06,5.06,0,0,0-1.34,1l-4.11,4.11L70.59,14.1l-1.26,1.25V9.88a2.37,2.37,0,0,0-.57-1.66A2.19,2.19,0,0,0,67,7.59H59.38a2.18,2.18,0,0,0-1.64.63,1.94,1.94,0,0,0-.5.85,6.64,6.64,0,0,0-.1,1h-3a1.83,1.83,0,0,0-1.11-2.34,2.09,2.09,0,0,0-.34-.08L39,7.66h0l-14.38,0h0l-12.48,0a.79.79,0,0,1-.6-.24L9.25,5.2h0L5.42,1.37A1.37,1.37,0,0,0,4.26.88,1.78,1.78,0,0,0,3,1.39a1.66,1.66,0,0,0-.53,1.22A2.06,2.06,0,0,0,3,3.91H3L7.28,8.22h0l4.35,4.31h0a.61.61,0,0,0,.07.14.4.4,0,0,1,0,.15.76.76,0,0,1,0,.21,1.72,1.72,0,0,0-.07.21h0L4.7,20.13l-1,1h0l-.52.52A1.91,1.91,0,0,0,3,24.08a1.66,1.66,0,0,0,1.26.4h9.36a4.47,4.47,0,0,0,1.77-.28,5,5,0,0,0,1.34-.95L19,21.14h0l1-1,2.36-2.23H26.8a.52.52,0,0,0,.37-.16l6.53-6.46h5.77l-3.76,3.85L30,20.74,30,20.8a2.13,2.13,0,0,0,1.49,3.65H52.68v0h.09A1.83,1.83,0,0,0,54.6,22.6v0a1.83,1.83,0,0,0-1.83-1.83h-.09l-3.72,0h-.75l.06-.08L54.94,14l2.18-2.2V23.54a.93.93,0,0,0,.93.93H78.89a.73.73,0,0,1,.46.13l6.53,6.53a1.39,1.39,0,0,0,1.16.49,1.75,1.75,0,0,0,1.27-.51,1.62,1.62,0,0,0,.53-1.22A2.12,2.12,0,0,0,88.39,28.64Z"/>-->
<!--    </svg>-->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: null,
    }
  },
  methods:{
    openY2K(){
      window.open('https://y2k.design', '_blank').focus();
    }
  },
  mounted() {
    let date = new Date();
    this.year = date.getFullYear();
  }
}
</script>

<style scoped>
.footer__text-rights{
  font-family: blackest_text_regular, serif;
  color: var(--color-magenta);
  font-size: 4.44vw;
  margin-bottom: 9vw;
}

.footer__text-rasp{
  font-family: blackest_extrabold, serif;
  color: var(--color-magenta);
  font-size: 4.44vw;
  margin-top: 8vw;
  margin-bottom: 1vw;
}
.cls-1-logo {
  fill: var(--color-magenta);
  transition: 0.3s;
}

.footer__icon-y2k {
  width: 20vw;
}
.footer__svg-arrow path {
  fill: var(--color-magenta);
}

.footer__svg-arrow {
  width: 8.05vw;
  height: 10.83vw;
}
.footer-bg{
  width: 100vw;
  height: 65vw;
  position: absolute;
  background-color: white;
  left: 0;
  top: 1595.9vw;
  text-align: center;
  padding-top: 8vw;
}
</style>