<template>
  <div v-touch:swipe.left="nextPage" v-touch:swipe.right="prevPage" class="gallery__mobile">


    <p class="text-slide">Lapozáshoz húzd jobbra <br/>vagy balra!</p>

    <div class="hamburger">
      <div class="hamburger-icon open" @click="closeGallery">
        <template v-for="(item, index) in 4">
          <span/>
        </template>
      </div>
    </div>


    <transition name="fade" mode="out-in">
      <div :key="page">
        <div class="vertical-center">
          <img :src="mobileGallery[page].src">
          <p>{{mobileGallery[page].name}}</p>
        </div>
      </div>
    </transition>


    <!--        <div class="d-flex justify-content-center">-->

    <!--        </div>-->
    <!--        <div class="d-flex justify-content-center">-->

    <!--        </div>-->

  </div>
</template>

<script>
export default {
  name: "MobileGallery",
  computed: {
    mobileGallery: function () {
      return this.$store.getters.mobileGallery;
    }
  },
  data() {
    return {
      page: 0
    }
  },
  methods: {
    closeGallery() {
      this.$store.commit('setMobileGallery', null)
    },
    nextPage() {
      this.page++;
      if (this.page === this.mobileGallery.length) this.page = 0;
    },
    prevPage() {
      this.page--;
      if (this.page === -1) this.page = this.mobileGallery.length - 1;
    },
  },
}
</script>

<style scoped>
.hamburger {
  position: absolute;
  top: 7.2vw;
  right: 4vw;
  z-index: 2;
}

.hamburger-icon {
  width: 8vw;
  height: 1vw;
  /*position: relative;*/
  /*margin: 32px auto;*/
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

}


.hamburger-icon span {
  background: white;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.hamburger-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-icon span:nth-child(2), .hamburger-icon span:nth-child(3) {
  top: 2vw;
}

.hamburger-icon span:nth-child(4) {
  top: 4vw;
}

.hamburger-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.hamburger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.text-slide {
  position: absolute;
  left: 5vw;
  top: 5vw;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-flow: column;
}

.gallery__mobile {
  position: fixed;
  top: 0;
  width: 100vw;
}

p {
  font-family: blackest_regular, serif;
  color: #fff;
  font-size: 6vw;
}

img {
  width: 90vw;
  margin-bottom: 3vw;
}
</style>