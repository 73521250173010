<template>
  <div class="footer">

    <div class="d-flex justify-content-center">
      <svg v-scroll-to="{ el: '#slider', offset: -100 }" class="footer__svg-arrow" viewBox="0 0 43 58">
        <path
            d="M40.66,20.62,22.84,2.8h0a2.11,2.11,0,0,0-.23-.19,2.17,2.17,0,0,0-1.35-.49h-.12l-.19,0a2.48,2.48,0,0,0-.27,0l-.16.05-.28.09-.15.09a2,2,0,0,0-.25.14l-.14.12-.21.18,0,0L1.4,22.48a2.35,2.35,0,0,0,.14,3.31,2.31,2.31,0,0,0,1.58.62,2.33,2.33,0,0,0,1.73-.75l14-15.23L19,55a2.35,2.35,0,0,0,2.34,2.34h0A2.34,2.34,0,0,0,23.72,55l-.17-44.82,13.8,13.8a2.34,2.34,0,0,0,3.31-3.31Z"/>
      </svg>
      <p class="footer__text-rasp">© Málna Manufaktúra {{ year }}.</p>
      <p class="footer__text-rights">Minden jog fenntartva!</p>

<!--      <svg class="footer__icon-y2k" viewBox="0 0 91.3 32.5" @click="openY2K">-->
<!--        <path class="cls-1-logo"-->
<!--              d="M88.39,28.64l-.09-.11L86.75,27h0l-7.3-7.25a1.08,1.08,0,0,1-.18-.24h0a.32.32,0,0,1,0-.14.76.76,0,0,1,0-.21h0a1.3,1.3,0,0,1,.08-.17h0a1.58,1.58,0,0,1,.12-.15l4.77-4.72,1.18-1.18L88,10.39a1.71,1.71,0,0,0,.49-1.23A1.67,1.67,0,0,0,88.1,8a1.63,1.63,0,0,0-1.25-.41H79a4.66,4.66,0,0,0-1.77.28,5.06,5.06,0,0,0-1.34,1l-4.11,4.11L70.59,14.1l-1.26,1.25V9.88a2.37,2.37,0,0,0-.57-1.66A2.19,2.19,0,0,0,67,7.59H59.38a2.18,2.18,0,0,0-1.64.63,1.94,1.94,0,0,0-.5.85,6.64,6.64,0,0,0-.1,1h-3a1.83,1.83,0,0,0-1.11-2.34,2.09,2.09,0,0,0-.34-.08L39,7.66h0l-14.38,0h0l-12.48,0a.79.79,0,0,1-.6-.24L9.25,5.2h0L5.42,1.37A1.37,1.37,0,0,0,4.26.88,1.78,1.78,0,0,0,3,1.39a1.66,1.66,0,0,0-.53,1.22A2.06,2.06,0,0,0,3,3.91H3L7.28,8.22h0l4.35,4.31h0a.61.61,0,0,0,.07.14.4.4,0,0,1,0,.15.76.76,0,0,1,0,.21,1.72,1.72,0,0,0-.07.21h0L4.7,20.13l-1,1h0l-.52.52A1.91,1.91,0,0,0,3,24.08a1.66,1.66,0,0,0,1.26.4h9.36a4.47,4.47,0,0,0,1.77-.28,5,5,0,0,0,1.34-.95L19,21.14h0l1-1,2.36-2.23H26.8a.52.52,0,0,0,.37-.16l6.53-6.46h5.77l-3.76,3.85L30,20.74,30,20.8a2.13,2.13,0,0,0,1.49,3.65H52.68v0h.09A1.83,1.83,0,0,0,54.6,22.6v0a1.83,1.83,0,0,0-1.83-1.83h-.09l-3.72,0h-.75l.06-.08L54.94,14l2.18-2.2V23.54a.93.93,0,0,0,.93.93H78.89a.73.73,0,0,1,.46.13l6.53,6.53a1.39,1.39,0,0,0,1.16.49,1.75,1.75,0,0,0,1.27-.51,1.62,1.62,0,0,0,.53-1.22A2.12,2.12,0,0,0,88.39,28.64Z"/>-->
<!--      </svg>-->

      <svg class="contact__icon-fb" viewBox="0 0 219.16 223.6" @click="openFacebook">
        <path class="cls-1" d="M121.65,7.66h-24l-.14,0h24.3Z"/>
        <path class="cls-1"
              d="M29.35,47.24C11.14,70.6,4.84,97.29,8.51,126.64c5.82,46.43,45.35,84.88,91.89,88.93,49.25,4.28,96.07-29,107.92-76.83,1.24-5,2.23-10.05,3.33-15.08v-21c-.34-1.61-.74-3.21-1-4.83-6.08-38.58-26.49-66.31-62.26-82.49-8.5-3.85-17.64-5.37-26.58-7.64H97.51c-1.55.4-3.09.89-4.66,1.16C66.76,13.52,45.53,26.48,29.35,47.24Zm67.28,36c.14-10.57,4.65-16.07,15-18a48.78,48.78,0,0,1,8.45-.55c10.36-.06,13.15,3.73,10.42,14-1.07,4-4.37,2.94-7,2.94-3.79,0-7,.38-7.57,5.18-.8,6.74.15,7.73,6.93,8.09,2.63.14,7-2.08,7.18,3.4.39,9.82-.76,11.89-6.83,12.7-7.37,1-7.37,1-7.37,8.67-.06,10.83-.17,21.67,0,32.49.07,4.05-1.33,5.7-5.49,5.62-16.09-.33-13.46,1.9-13.71-13.33v-10c0-5.83-.13-11.67,0-17.5.08-3.36-.9-5.45-4.72-5.36-3.15.08-4.45-1.76-4.35-4.76a57.18,57.18,0,0,0,0-6c-.26-3.43.92-5.46,4.74-5.31,3.24.14,4.38-1.75,4.32-4.75C96.56,88.25,96.63,85.75,96.63,83.25Z"/>
      </svg>
      <svg class="contact__icon-ins" viewBox="0 0 219.16 223.6" @click="openInstagram">
        <path class="cls-1"
              d="M152.51,133.79c.46-14.15.46-28.34,0-42.49-.4-12-6.42-18-18.25-19.2A227.43,227.43,0,0,0,90.4,72c-13.13,1.14-19.16,7.27-19.59,20.47-.19,6.82,0,13.66,0,20.49,0,7-.27,14,0,21,.44,11.62,6.25,17.9,17.72,19.11a225.11,225.11,0,0,0,44.88.08C146,152,152.1,146.17,152.51,133.79Zm-41,5.29A26.56,26.56,0,0,1,85,112.79C84.7,98.37,97,86.16,111.65,86.1H112a26.1,26.1,0,0,1,25.84,26.36C137.91,127.65,126.64,139.08,111.52,139.08ZM138.43,91.6c-3.71,0-5.75-2.43-5.71-6.09s2.06-5.46,5.65-5.46,5.8,2,6.21,5.55C144.28,89.6,142.14,91.63,138.43,91.6Z"/>
        <path class="cls-1"
              d="M111.47,97.16c-8.5,0-14.81,6.57-14.82,15.38,0,9.13,6.33,15.6,15.18,15.49,8.61-.11,14.92-6.45,14.94-15.31A15.22,15.22,0,0,0,111.47,97.16Z"/>
        <path class="cls-1" d="M97.71,216.07h23l.37-.12H97.17Z"/>
        <path class="cls-1"
              d="M208.24,139.61c1.33-5.13,2.32-10.36,3.47-15.54v-21a26.26,26.26,0,0,1-.89-3.35C207,69.8,193.34,45.61,169.05,27.52,126.9-3.84,65.46,2.05,29.75,47.16,10.89,71,4.07,98.5,8.87,128.41c5.47,34,23.7,59.48,53.9,76.24,10.74,6,22.53,8.77,34.4,11.3h23.91a27.51,27.51,0,0,1,3-.8C164.24,209.22,198,178.92,208.24,139.61ZM85.4,164.16c-15.56-2-25-12.72-25.69-28.31s-.67-30.91,0-46.35C60.39,73.24,70.61,62.39,86.8,61c16.77-1.47,33.57-1.83,50.32.09s26,12.64,26.56,29.55c.19,7.47,0,15,0,22.47l0,.06c0,6.16.05,12.32,0,18.48-.18,20.41-9.27,31.43-29.49,33C118,165.87,101.62,166.22,85.4,164.16Z"/>
      </svg>


    </div>
  </div>


</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: null,
    }
  },
  methods: {
    openFacebook() {
      window.open('https://www.facebook.com/malnamanufaktura/', '_blank').focus();
    },
    openInstagram() {
      window.open('https://www.instagram.com/malnamanufaktura/', '_blank').focus();
    },
    openY2K(){
      window.open('https://y2k.design', '_blank').focus();
    }
  },
  mounted() {
    let date = new Date();
    this.year = date.getFullYear();
  }
}
</script>

<style scoped>

.footer{
  padding-top: 5vw;
}

p{
  padding-top: 0.65vw;
}
.footer__text-rights{
  font-family: blackest_text_regular, serif;
  color: var(--color-magenta);
  font-size: 1.2vw;
  margin-right: 1vw;
}

.footer__text-rasp{
  margin-right: 0.5vw;
  font-family: blackest_extrabold, serif;
  color: var(--color-magenta);
  font-size: 1.2vw;
}

.row{
  width: 1200px;
}

.contact__icon-ins {
  cursor: pointer;
  width: 3vw;
  height: 3vw;
}

.contact__icon-fb {
  cursor: pointer;
  width: 3vw;
  height: 3vw;
  margin-right: 0.3vw;
  margin-left: 6vw;
}

.contact__icon-fb:hover .cls-1, .contact__icon-ins:hover .cls-1 {
  fill: var(--color-violet);
  transition: 0.3s;
}

p {

}

.cls-1 {
  fill: var(--color-magenta);
  transition: 0.3s;
}

.cls-1-logo {
  fill: var(--color-magenta);
  transition: 0.3s;
}


.footer__icon-y2k:hover .cls-1-logo {
  fill: #0cffae;
  transition: 0.3s;
}

.footer__icon-y2k {
  width: 6vw;
  cursor: pointer;
  margin-right: 6vw;
}


.footer__svg-arrow {
  margin-right: 6vw;
  cursor: pointer;
  width: 2.92vw;
  height: 2.15vw;
  margin-top: 0.4vw;
}

.footer__svg-arrow:hover path {
  fill: var(--color-violet);
  transition: 0.3s;
}

.footer__svg-arrow path {
  fill: var(--color-magenta);
  transition: 0.3s;
}

.footer {
  position: absolute;
  top: 477.1vw;
  background-color: white;
  width: 100%;
  height: 12.6vw;
}
</style>