<template>
  <div class="contact">
    <div class="contact-ellipse ellipse-bg-gradient"/>
    <img id="contact__drips" src="../../assets/img/contact-drips.webp" alt="contact-drips"/>
    <img id="contact__icon" src="../../assets/img/contact-icon.webp" alt="contact-icon"/>
    <img id="contact__cake" src="../../assets/img/contact-cake.webp" alt="contact-cake"/>
    <img id="contact__rasp" src="../../assets/img/contact__raspberry.webp" alt="contact-rasp"/>
    <img id="contact__cake-2" src="../../assets/img/contact_cake-2.webp" alt="contact-cake-2"/>
    <img id="contact__flower" src="../../assets/img/contact-flower.webp" alt="contact-flower"/>
    <img id="contact__stars" src="../../assets/img/contact-stars.webp" alt="contact-stars"/>
    <p class="contact__text-top">Állunk rendelkezésedre!</p>

    <b-form-input id="contact__form-name" v-model="form.name" placeholder="Név"/>
    <b-form-input id="contact__form-email" v-model="form.email" placeholder="E-mail cím"/>
    <b-form-input id="contact__form-phone" v-model="form.phone" placeholder="Telefonszám (opcionális)"/>

    <b-form-textarea
        id="contact__form-message"
        v-model="form.text"
        placeholder="Üzenet"
        rows="3"
        max-rows="6"
    />

    <b-button class="btn-contact" :disabled="isDisabled" @click="sendForm">Küldés</b-button>
    <p class="contact__text-privacy">*Adataidat az adatvédelmi törvényeknek megfelelően bizalmasan kezeljük, kizárólag
      kapcsolattartás céljából tároljuk, harmadik félnek nem adjuk át.</p>

    <transition name="fade" mode="out-in">
    <p class="contact__text-sent" v-if="hasSent">
      Az üzenet sikeresen elküldve,
      <br/>
      hamarosan felvesszük
      <br/>
      veled a kapcsolatot!
    </p>
    </transition>

    <p class="contact__text-rasp">Málna manufaktúra</p>
    <p class="contact__text-email">
      info (kukac) malnamanufaktura.hu
      <br/>
      +36 30 633 9033
    </p>


    <svg class="contact__icon-fb" viewBox="0 0 219.16 223.6" @click="openFacebook">
      <path class="cls-1" d="M121.65,7.66h-24l-.14,0h24.3Z"/>
      <path class="cls-1"
            d="M29.35,47.24C11.14,70.6,4.84,97.29,8.51,126.64c5.82,46.43,45.35,84.88,91.89,88.93,49.25,4.28,96.07-29,107.92-76.83,1.24-5,2.23-10.05,3.33-15.08v-21c-.34-1.61-.74-3.21-1-4.83-6.08-38.58-26.49-66.31-62.26-82.49-8.5-3.85-17.64-5.37-26.58-7.64H97.51c-1.55.4-3.09.89-4.66,1.16C66.76,13.52,45.53,26.48,29.35,47.24Zm67.28,36c.14-10.57,4.65-16.07,15-18a48.78,48.78,0,0,1,8.45-.55c10.36-.06,13.15,3.73,10.42,14-1.07,4-4.37,2.94-7,2.94-3.79,0-7,.38-7.57,5.18-.8,6.74.15,7.73,6.93,8.09,2.63.14,7-2.08,7.18,3.4.39,9.82-.76,11.89-6.83,12.7-7.37,1-7.37,1-7.37,8.67-.06,10.83-.17,21.67,0,32.49.07,4.05-1.33,5.7-5.49,5.62-16.09-.33-13.46,1.9-13.71-13.33v-10c0-5.83-.13-11.67,0-17.5.08-3.36-.9-5.45-4.72-5.36-3.15.08-4.45-1.76-4.35-4.76a57.18,57.18,0,0,0,0-6c-.26-3.43.92-5.46,4.74-5.31,3.24.14,4.38-1.75,4.32-4.75C96.56,88.25,96.63,85.75,96.63,83.25Z"/>
    </svg>


    <svg class="contact__icon-ins" viewBox="0 0 219.16 223.6" @click="openInstagram">
      <path class="cls-1"
            d="M152.51,133.79c.46-14.15.46-28.34,0-42.49-.4-12-6.42-18-18.25-19.2A227.43,227.43,0,0,0,90.4,72c-13.13,1.14-19.16,7.27-19.59,20.47-.19,6.82,0,13.66,0,20.49,0,7-.27,14,0,21,.44,11.62,6.25,17.9,17.72,19.11a225.11,225.11,0,0,0,44.88.08C146,152,152.1,146.17,152.51,133.79Zm-41,5.29A26.56,26.56,0,0,1,85,112.79C84.7,98.37,97,86.16,111.65,86.1H112a26.1,26.1,0,0,1,25.84,26.36C137.91,127.65,126.64,139.08,111.52,139.08ZM138.43,91.6c-3.71,0-5.75-2.43-5.71-6.09s2.06-5.46,5.65-5.46,5.8,2,6.21,5.55C144.28,89.6,142.14,91.63,138.43,91.6Z"/>
      <path class="cls-1"
            d="M111.47,97.16c-8.5,0-14.81,6.57-14.82,15.38,0,9.13,6.33,15.6,15.18,15.49,8.61-.11,14.92-6.45,14.94-15.31A15.22,15.22,0,0,0,111.47,97.16Z"/>
      <path class="cls-1" d="M97.71,216.07h23l.37-.12H97.17Z"/>
      <path class="cls-1"
            d="M208.24,139.61c1.33-5.13,2.32-10.36,3.47-15.54v-21a26.26,26.26,0,0,1-.89-3.35C207,69.8,193.34,45.61,169.05,27.52,126.9-3.84,65.46,2.05,29.75,47.16,10.89,71,4.07,98.5,8.87,128.41c5.47,34,23.7,59.48,53.9,76.24,10.74,6,22.53,8.77,34.4,11.3h23.91a27.51,27.51,0,0,1,3-.8C164.24,209.22,198,178.92,208.24,139.61ZM85.4,164.16c-15.56-2-25-12.72-25.69-28.31s-.67-30.91,0-46.35C60.39,73.24,70.61,62.39,86.8,61c16.77-1.47,33.57-1.83,50.32.09s26,12.64,26.56,29.55c.19,7.47,0,15,0,22.47l0,.06c0,6.16.05,12.32,0,18.48-.18,20.41-9.27,31.43-29.49,33C118,165.87,101.62,166.22,85.4,164.16Z"/>
    </svg>

    <div class="mapouter">
      <img src="../../assets/img/8.jpg" />
<!--      <div class="gmap_canvas">-->
<!--        <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"-->
<!--                src="https://maps.google.com/maps?width=792&amp;height=630&amp;hl=en&amp;q=Székesfehérvár, Milleniumi emlékmű&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">-->
<!--        </iframe>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  computed:{
    isDisabled: function (){
      return !this.form.name || !this.form.email || !this.form.text;
    },
  },
  methods: {
    openFacebook() {
      window.open('https://www.facebook.com/malnamanufaktura/', '_blank').focus();
    },
    openInstagram() {
      window.open('https://www.instagram.com/malnamanufaktura/', '_blank').focus();
    },
    async sendForm(){
      let method = 'post';
      let route = '/sendemail';
      let data = this.form;
      let result = await this.apiRequest(method, route, data);
      if(result){
        this.hasSent = true;
        setTimeout(() => { this.hasSent = false }, 10000);
      }
    },
  },

  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        text: null,
      },
      hasSent: false
    }
  },
}
</script>

<style scoped>

.contact__text-sent{
  position: absolute;
  color: white;
  font-size: 1.3vw;
  font-family: blackest_regular, Arial,serif;
  left: 29.68vw;
  top: 429.68vw;
  text-align: right;
  line-height: 1.2;
}
.mapouter {
  position: absolute;
  text-align: right;
  /*width:600px;*/
  /*height:400px;*/

  width: 41.25vw;
  height: 35.7vw;
  left: -20vw;
  top: 441.40vw;
}

.mapouter img{
  height: 35.7vw;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 41.25vw;
  height: 35.7vw;
}

.gmap_iframe {
  width: 41.25vw !important;
  height: 35.7vw !important;
}

.contact__icon-ins {
  position: absolute;
  cursor: pointer;
  width: 4.30vw;
  height: 4.14vw;
  left: 54.18vw;
  top: 464.53vw;
}

.contact__icon-fb {
  position: absolute;
  cursor: pointer;
  width: 4.30vw;
  height: 4.14vw;
  left: 48.58vw;
  top: 464.53vw;
}

.contact__icon-fb:hover .cls-1, .contact__icon-ins:hover .cls-1 {
  fill: var(--color-violet);
  transition: 0.3s;
}

.cls-1 {
  fill: white;
  transition: 0.3s;
}

.contact__text-email {
  position: absolute;
  line-height: 3.59vw;
  color: white;
  font-size: 2.18vw;
  font-family: blackest_regular, serif;
  width: 35.62vw;
  height: 7.29vw;
  left: 48.64vw;
  top: 454.86vw;
}

.contact__text-rasp {
  position: absolute;
  color: white;
  font-size: 3.85vw;
  font-family: blackest_heavy, serif;
  left: 49.01vw;
  top: 449.27vw;

}

#contact__form-phone {
  position: absolute;
  left: 19.53vw;
  top: 422.44vw;
}

#contact__form-email {
  position: absolute;
  left: 19.53vw;
  top: 417.76vw;
}

#contact__form-name {
  position: absolute;
  left: 19.53vw;
  top: 413.02vw;
}

.contact__text-privacy {
  position: absolute;
  text-align: left;
  color: white;
  font-size: 0.78vw;
  font-family: blackest_regular, serif;
  width: 20.52vw;
  height: 4.42vw;
  left: 61.77vw;
  top: 430.36vw;
}

.btn-contact {
  position: absolute;
  left: 48.75vw;
  top: 429.84vw;
}

#contact__form-message {
  position: absolute;
  width: 33.125vw;

  /*width: 27vw;*/
  left: 48.75vw;
  top: 412.96vw;
  height: 12.8vw !important;
  margin-bottom: 1.6vw;

}

input {
  width: 27vw;
  height: 3.3vw;
  margin-bottom: 1.3vw;

}

.contact__text-top {
  position: absolute;
  color: white;
  font-size: 3.75vw;
  font-family: blackest_heavy, serif;
  white-space: pre;
  width: 44.16vw;
  height: 4.94vw;
  left: 27.81vw;
  top: 404.94vw;
}

#contact__stars {
  position: absolute;
  -ms-transform: rotate(16.00deg);
  -webkit-transform: rotate(16.00deg);
  transform: rotate(16.00deg);
  width: 16.77vw;
  height: 17.34vw;
  left: 78.69vw;
  top: 455.2vw;
}


#contact__flower {
  position: absolute;
  width: 21.97vw;
  height: 22.76vw;
  left: 63.17vw;
  top: 461.30vw;
}

#contact__cake-2 {
  position: absolute;
  width: 16.04vw;
  height: 16.61vw;
  left: 77.96vw;
  top: 431.97vw;
}

#contact__rasp {
  position: absolute;
  -ms-transform: rotate(49.00deg);
  -webkit-transform: rotate(49.00deg);
  transform: rotate(49.00deg);
  width: 16.04vw;
  height: 16.61vw;
  left: 43.41vw;
  top: 438.33vw;
}

#contact__cake {
  position: absolute;
  -ms-transform: rotate(21.00deg);
  -webkit-transform: rotate(21.00deg);
  transform: rotate(21.00deg);
  width: 13.02vw;
  height: 13.54vw;
  left: 13.07vw;
  top: 426.25vw;
}

.contact-ellipse {
  position: absolute;
  width: 66vw;
  height: 66vw;
  left: 60.15vw;
  top: 423.69vw;
  border-radius: 50%;

}

#contact__icon {
  position: absolute;
  -ms-transform: rotate(13.00deg);
  -webkit-transform: rotate(13.00deg);
  transform: rotate(13.00deg);
  width: 32.23vw;
  height: 26.14vw;
  left: 1.09vw;
  top: 393.02vw;
}

#contact__drips {
  position: absolute;
  width: 113.33vw;
  height: 44.68vw;
  left: -3.02vw;
  top: 390.67vw;
}
</style>