<template>
  <div>
    <div class="slide__1-ellipse-left-overlay-big anim__fade-in-left-opacity-25 anim__duration-0-1"/>
    <div class="slide__1-ellipse-left-overlay-medium anim__fade-in-left-opacity anim__duration-0-3"/>
    <div class="slide__1-ellipse-left-violet-big ellipse-bg-gradient anim__fade-in-left anim__duration-0-5"/>
    <img id="slide__1-cake-left" src="../../../assets/img/slider2a-d6a07479.webp" class="anim__fade-in-left anim__duration-0-5" alt="slide-1-cake-left"/>
    <div class="slide__1-ellipse-left-violet-tiny ellipse-bg-gradient anim__fade-in-bottom anim__duration-0-7"/>
    <div class="slide__1-ellipse-right-overlay-big anim__fade-in-right-opacity-25 anim__duration-0-3"/>
    <div class="slide__1-ellipse-right-overlay-medium anim__fade-in-right-opacity anim__duration-0-5"/>
    <div class="slide__1-ellipse-right-violet-big ellipse-bg-gradient anim__fade-in-right anim__duration-0-7"/>
    <img id="slide__1-cake-right" src="../../../assets/img/slider2b.webp" class="anim__fade-in-right anim__duration-0-7" alt="slider-1-cake-right"/>
    <div class="slide__1-ellipse-right-violet-tiny ellipse-bg-gradient anim__fade-in-bottom anim__duration-0-9"/>
    <p class="slide__1-text-salt anim__fade-in-bottom anim__duration-0-3">Édes vagy sós?</p>
    <p class="slide__1-text-order anim__fade-in-bottom anim__duration-0-5">Kiszállítással vagy elvitellel?</p>
    <b-button v-scroll-to="{ el: '#contact__drips', offset: -150}" class="slide__1-btn btn-outlined anim__fade-in-bottom anim__duration-0-7">Ajánlatot kérek!</b-button>
  </div>
</template>

<script>
export default {
name: "Second"
}
</script>

<style scoped>
.slide__1-btn{
  position: absolute;
  left: 42.9vw;
  top: 42.5vw;
}

.slide__1-text-salt{
  position: absolute;
  text-align: left;
  color: white;
  font-size: 3.4vw;
  font-family: blackest_regular, serif;
  width: 23.4vw;
  /* height: 85.00px; */
  left: 38.3vw;
  top: 20.2vw;
}

.slide__1-text-order{
  position: absolute;
  text-align: center;
  line-height: 6.9vw;
  color: white;
  font-size: 7.3vw;
  font-family: blackest_heavy, serif;
  width: 57.8vw;
  height: 17.9vw;
  left: 21vw;
  top: 25.2vw;
}
#slide__1-cake-right{
  position: absolute;
  width: 38.3vw;
  height: 38.3vw;
  left: 54vw;
  top: 14.5vw;
}

.slide__1-ellipse-right-violet-tiny{
  position: absolute;
  border-radius: 50%;
  width: 4.3vw;
  height: 4.3vw;
  left: 84.1vw;
  top: 17.8vw;
}

.slide__1-ellipse-right-overlay-big{
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
  background-color: white;
  width: 53.34vw;
  height: 53.34vw;
  left: 59vw;
  top: 9.3vw;

}

.slide__1-ellipse-right-overlay-medium{
  position: absolute;
  border-radius: 50%;
  opacity: 0.25;
  background-color: white;
  width: 33.14vw;
  height: 33.14vw;
  left: 58.85vw;
  top: 18vw;
}

.slide__1-ellipse-right-violet-big{
  position: absolute;
  border-radius: 50%;

  width: 28.5vw;
  height: 28.5vw;
  left: 58.85vw;
  top: 18.5vw;
}

.slide__1-ellipse-left-overlay-big{
  position: absolute;
  border-radius: 50%;
  opacity: 0.15;
  background-color: white;
  width: 53.34vw;
  height: 53.34vw;
  left: -11vw;
  top: -0.46vw;
}

.slide__1-ellipse-left-overlay-medium{
  position: absolute;
  border-radius: 50%;
  opacity: 0.25;
  background-color: white;
  width: 38.25vw;
  height: 38.25vw;
  left: 4vw;
  top: 8.3vw;
}

.slide__1-ellipse-left-violet-big{
  position: absolute;
  border-radius: 50%;
  width: 33.1vw;
  height: 33.1vw;
  left: 8.7vw;
  top: 11.49vw;
}

#slide__1-cake-left{
  position: absolute;
  width: 61.7vw;
  height: 45.9vw;
  left: 4vw;
  top: 6.9vw;
}

.slide__1-ellipse-left-violet-tiny{
  position: absolute;
  border-radius: 50%;
  width: 7.6vw;
  height: 7.6vw;
  left: 15.3vw;
  top: 41.9vw;
}
</style>