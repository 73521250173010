<template>
  <div class="desktop">
    <transition name="fade" mode="out-in">
      <div v-if="isVideo" key="desktop-video" class="video">
        <video id="desktop__video" src="../assets/video.webm" autoplay muted/>
        <b-button class="btn-contact btn-next anim__fade-in-bottom anim__duration-0-9" @click="isVideo = false">
          Kattints a folytatáshoz...
        </b-button>
        <b-button class="btn-contact btn-sound anim__fade-in-bottom anim__duration-0-9" @click="muted = !muted">
          <svg viewBox="0 0 66 66">
            <path :class="{'cls-unmuted': !muted, 'cls-muted': muted}"
                d="M50,41.68a3.12,3.12,0,0,0-.41-1.26l-.54-25L22.83,17.41,26.89,42a12.92,12.92,0,0,0-6-.78c-5.05.47-8.91,3.41-8.62,6.57s4.63,5.34,9.68,4.87c4.9-.45,8.66-3.22,8.63-6.27h0l0-.29h0v0l-3-18H46.29l.22,10a10.7,10.7,0,0,0-4.54-.5c-4.18.38-7.37,2.81-7.13,5.42s3.83,4.42,8,4S50.21,44.29,50,41.68ZM27.14,25.12l-.82-5L46.09,18.7l.14,6.42Z"/>
          </svg>
        </b-button>
      </div>

      <div v-else key="desktop-page">
        <Slider/>
        <About/>
        <Drips/>
        <Raspberries/>
        <Contact/>
        <Galleries/>
<!--        <Newsletter/>-->
        <Footer/>
        <Navbar/>
      </div>
    </transition>


  </div>
</template>

<script>
import Galleries from "@/sections/desktop/Galleries";
import Raspberries from "@/sections/desktop/Raspberries";
import About from "@/sections/desktop/About";
import Navbar from "@/components/desktop/Navbar";
import Slider from "@/sections/desktop/Slider";
import Drips from "@/sections/desktop/Drips";
import Contact from "@/sections/desktop/Contact";
import Newsletter from "@/sections/desktop/Newsletter";
import Footer from "@/sections/desktop/Footer";

export default {
  name: "Desktop.vue",
  data() {
    return {
      isVideo: true,
      muted: true,
    }
  },
  mounted() {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      window.scrollTo({ top: 0 });
    }
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = () => {
      window.scrollTo(x, y);
    };
  },
  watch: {
    isVideo() {
      window.onscroll = () => {
      };
    },
    muted(){
        let video = document.getElementById("desktop__video");
        video.muted = !video.muted;
    }
  },
  components: {
    Contact,
    About,
    Navbar,
    Slider,
    Drips,
    Raspberries,
    Galleries,
    Newsletter,
    Footer
  }
}
</script>

<style scoped>
.btn-sound{
  position: absolute;
  bottom: 3vw;
  top: 10px;
  right: 10px;
  font-size: 1vw !important;
  font-family: blackest_text_regular, serif !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  padding-left: 7.5px !important;
  padding-top: 4px !important
}
.btn-next {
  position: absolute;
  bottom: 3vw;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1vw !important;
  font-family: blackest_text_regular, serif !important;
}

#desktop__video {
    width: 100vw;
  //max-height: 100vh;
}

.desktop {
  /*background-color: #FD76B9;*/

  /*background: rgb(220,154,187);*/
  background: linear-gradient(180deg, rgba(220, 154, 187, 1) 0%, rgba(254, 5, 130, 1) 21%, rgba(199, 22, 186, 1) 36%, rgba(142, 39, 242, 1) 56%, rgba(216, 22, 134, 1) 100%);
  height: 480vw;
}

svg {
  width: 30px !important;
}

.cls-muted {
  fill: #fff !important;
}

.cls-unmuted{
  fill: #FE63AE !important;
}


</style>