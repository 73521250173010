<template>
  <div class="gallery">
    <svg class="svg__close" viewBox="0 0 98 98" @click="closeGallery">
      <path class="path-close"
            d="M59,49,78.64,29.3a6.5,6.5,0,1,0-9.19-9.19L49.79,39.77,30.13,20.11a6.5,6.5,0,0,0-9.19,9.19L40.6,49,20.94,68.62a6.5,6.5,0,0,0,9.19,9.2L49.79,58.16,69.45,77.82a6.5,6.5,0,1,0,9.19-9.2Z"/>
    </svg>

    <svg viewBox="0 0 34.803 25.605" class="arrow arrow-prev" @click="prev">
      <path
          d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
          transform="translate(0 -0.829)"/>
    </svg>

    <svg viewBox="0 0 34.803 25.605" class="arrow arrow-next" @click="next">
      <path
          d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
          transform="translate(0 -0.829)"/>
    </svg>


    <transition name="fade" mode="out-in">
      <div :key="page">
        <div class="d-flex justify-content-center">
          <img :src="items[page].srcGallery"/>
        </div>

        <div class="d-flex justify-content-center">
          <p>{{ items[page].name }}</p>
          <p class="text-year" v-if="!!items[page].year"> - {{items[page].year}}</p>
        </div>
      </div>

    </transition>


  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      page: 0,
      items: [],
    }
  },
  methods: {
    closeGallery() {
      this.$store.commit('gallery', null);
    },
    next(){
      this.page++;
      if(this.page === this.items.length) this.page = 0;
    },
    prev(){
      this.page--;
      if(this.page === -1) this.page = this.items.length - 1;
    },
  },
  mounted() {
    let x = window.scrollX;
    let y = window.scrollY;
    window.onscroll = () => {
      window.scrollTo(x, y);
    };
    this.page = this.$store.getters.galleryIndex;
    this.items = this.$store.getters.gallery;
  },
  destroyed() {
    window.onscroll = () => {
    };
  }
}
</script>

<style scoped>
.text-year{
  margin-left: 0.7vw;
}
.arrow-prev {
  position: absolute;
  width: 1.8vw;
  left: 1.45vw;
  top: 22vw;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);

}


.arrow-next {
  position: absolute;
  width: 1.8vw;
  right: 1.45vw;
  top: 22vw;
  cursor: pointer;
}

.arrow {
  fill: white;
  transition: 0.3s;
}

.arrow:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}

p{
  color: white;
  font-family: blackest_text_regular, serif;
  font-size: 2vw;
  margin-top: 3vw;
}
img {
  height: 33vw;
  margin-top: 2vw;
}

.svg__close {
  cursor: pointer;
  position: absolute;
  right: 1vw;
  top: 1vw;
  /*left: 51.22vw;*/
  /*top: 229.3vw;*/
  width: 2.5vw;
  /*height: 4.32vw;*/
}

.svg__close .path-close {
  fill: white;
  transition: 0.3s;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.gallery {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  background-color: rgba(255, 5, 130, .8);
}
</style>