<template>
  <div v-touch:swipe.left="nextPage" v-touch:swipe.right="prevPage" id="slider">
    <transition name="fade" mode="out-in">
      <div v-if="page === 0" key="first">
        <SliderFirst/>
      </div>

      <div v-if="page === 1" key="second">
        <SliderSecond/>
      </div>

      <div v-if="page === 2" key="third">
        <SliderThird/>
      </div>
    </transition>

    <b-button class="btn-ellipse slider__pagination-first" @click="toPage(0)" :disabled="page === 0"/>
    <b-button class="btn-ellipse slider__pagination-second" @click="toPage(1)" :disabled="page === 1"/>
    <b-button class="btn-ellipse slider__pagination-third" @click="toPage(2)" :disabled="page === 2"/>
    <b-button v-scroll-to="{ el: '#contact__drip', offset: -100}" class="btn-outlined">Ajánlatot kérek!</b-button>
  </div>
</template>

<script>
import SliderFirst from "@/sections/mobile/Slider/SliderFirst";
import SliderSecond from "@/sections/mobile/Slider/SliderSecond";
import SliderThird from "@/sections/mobile/Slider/SliderThird";
export default {
  name: "Slider",
  components:{ SliderFirst, SliderSecond, SliderThird },
  data() {
    return {
      page: 0,
      timer: null
    }
  },
  methods:{
    toPage(page){
      this.page = page;
    },
    nextPage(){
      this.page++;
      if(this.page === 3) this.page = 0;
    },
    prevPage(){
      this.page--;
      if(this.page === -1) this.page = 2;
    }
  },
}
</script>

<style scoped>
.btn-outlined{
  position: absolute;
  left: 25.55vw;
  top: 123.05vw;
}
.slider__pagination-first {
  position: absolute;
  left: 38.33vw;
  top: 158.33vw;
}

.slider__pagination-second {
  position: absolute;
  left: 47.77vw;
  top: 158.33vw;
}

.slider__pagination-third {
  position: absolute;
  left: 57.22vw;
  top: 158.33vw;
}
</style>