<template>
  <div id="navbar" class="navbar d-flex justify-content-center">

    <video id="waves" src="../../assets/anims/header-wave.webm" autoplay muted loop/>
<!--    <img id="waves" src="../../assets/img/hullamvonal-23.webp" alt="waves"/>-->
    <img id="logo" src="../../assets/img/logo.webp" alt="logo"/>

    <b-row class="navbar__items">
      <b-col class="navbar__col-left">
        <p v-scroll-to="{ el: '#about__rasp', offset: -100,  }">Rólunk<img src="../../assets/img/arrow.png" alt="arrow"/></p>
        <p v-scroll-to="{ el: '#rasperries__img', offset: -150 }">Málnák<img src="../../assets/img/arrow.png" alt="arrow"/></p>
      </b-col>
      <b-col class="navbar__col-right">
        <p v-scroll-to="{ el: '#gallery-cookie__img', offset: -150 }">Galéria<img src="../../assets/img/arrow.png" alt="arrow"/></p>
        <p v-scroll-to="{ el: '#contact__drips', offset: -150}">Kapcsolat<img src="../../assets/img/arrow.png" alt="arrow"/></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: ['refs'],
  methods:{
    scrollTo(target){
    },
    scrollEvent(){
      if (window.scrollY > 10) {
        document.getElementById('logo').classList.add('logo-animation');
        document.getElementById('waves').classList.add('waves-animation');
      }
      if (window.scrollY < 300) {
        document.getElementById('logo').classList.remove('logo-animation');
        document.getElementById('waves').classList.remove('waves-animation');
      }
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollEvent);
  }
}
</script>

<style scoped>

.navbar {
  position: fixed;
  top: 0;
  border-bottom: #FD0583 5px solid;
  background-color: white;
  width: 100%;

  height: 7.3vw;
  /*z-index: 3;*/
}

.navbar__items {
  position: relative;
  width: 1100px;
}

.navbar__items p {
  display: inline;
  color: var(--color-grey);
  font-family: blackest_text_regular, serif;
  cursor: pointer;
  font-size: 16pt;
  z-index: 10 !important;
  transition: 0.3s;
}

.navbar__items p:nth-child(1) {
  margin-right: 5.2vw;
}

.navbar__items p img {
  margin-left: 5px;
  margin-top: -2px;
}

.navbar__items p:hover {
  color: black;
  transition: 0.3s;
}

.navbar__col-right {
  padding-left: 450px !important;
}

#logo {
  width: 15.625vw;
  position: absolute;
  margin-top: 2.6vw;
  transition: all linear .2s
}

#waves {
  display: block;
  width: 31.25vw;
  position: absolute;
  margin-top: 7.8vw;
  margin-left: 0.26vw;
  transition: all linear .2s
}

.logo-animation {
  margin-top: 0 !important;
  width: 200px !important;
  transition: all linear .2s !important
}

.waves-animation{
  margin-top: 0 !important;
  width: 200px !important;
  transition: all linear .2s !important
}
</style>