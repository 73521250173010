<template>
  <div class="gallery-wedding">
    <transition name="fade" mode="out-in">
      <div v-if="!isOpened" key="gallery-wedding-closed">
        <div class="gallery-wedding__bg-closed"/>
        <img id="gallery-wedding__img" src="../../../assets/img/galleries/wedding/closed.webp" alt="gallery-wedding"/>
        <video id="gallery-wedding__anim" src="../../../assets/anims/gallery-wedding.webm" autoplay muted/>
        <p class="gallery-wedding__text" @click="toggleOpen">
          Esküvői torta

          <svg viewBox="0 0 137.79 137.79">
            <circle class="cls-1" cx="68.89" cy="68.89" r="66"/>
            <circle class="cls-2" cx="68.89" cy="68.89" r="65.5"/>
            <path class="cls-3" d="M68,67.86Z"/>
            <path class="cls-4"
                  d="M97.59,61H75.81V39.18a7.56,7.56,0,0,0-7.55-7.56h0a7.55,7.55,0,0,0-7.55,7.56V61H38.92a7.56,7.56,0,0,0,0,15.12H60.7V97.84a7.56,7.56,0,1,0,15.11,0V76.07H97.59a7.58,7.58,0,0,0,7.56-7.56A7.58,7.58,0,0,0,97.59,61Z"/>
          </svg>

        </p>

      </div>

      <div v-else key="gallery-wedding-opened">
        <div class="gallery-wedding__bg-opened"/>

        <svg class="svg__close" viewBox="0 0 98 98" @click="toggleOpen">
          <path class="path-close"
                d="M59,49,78.64,29.3a6.5,6.5,0,1,0-9.19-9.19L49.79,39.77,30.13,20.11a6.5,6.5,0,0,0-9.19,9.19L40.6,49,20.94,68.62a6.5,6.5,0,0,0,9.19,9.2L49.79,58.16,69.45,77.82a6.5,6.5,0,1,0,9.19-9.2Z"/>
        </svg>
        <p class="gallery-wedding__text-opened">Esküvői torta</p>
        <img id="gallery-wedding__img-opened" src="../../../assets/img/galleries/wedding/opened.png" alt="gallery-wedding-opened"/>

        <b-button class="btn-ellipse btn-ellipse-first" @click="toPage(0)" :disabled="page === 0"/>
        <b-button class="btn-ellipse btn-ellipse-second" @click="toPage(1)" :disabled="page === 1"/>
        <b-button class="btn-ellipse btn-ellipse-third" @click="toPage(2)" :disabled="page === 2"/>

        <svg class="arrow-next" viewBox="0 0 34.803 25.605" @click="nextPage">
          <path
              d="M20.783,25.849a2,2,0,0,1,0-2.829l6.889-6.889H2a2,2,0,0,1,0-4H28.671L20.782,4.243a2,2,0,0,1,2.829-2.829L34.217,12.02a2,2,0,0,1,.576,1.611,2,2,0,0,1-.576,1.611L23.611,25.849a2,2,0,0,1-2.828,0Z"
              transform="translate(0 -0.829)"/>
        </svg>

        <transition name="fade" mode="out-in">
          <div :key="page">
            <ImageBlock
                :id="imagesSweet[page * 3].id"
                :src="imagesSweet[page * 3].src"
                :name="imagesSweet[page * 3].name"
                :image-array="imagesSweet"
                :index="page * 3"
                topImage="311.4vw"
                topMiddle="15.5vw"
                variant="big"
                container-pos-left="0"
            />
            <ImageBlock
                :id="imagesSweet[page * 3 + 1].id"
                :src="imagesSweet[page * 3 + 1].src"
                :name="imagesSweet[page * 3 + 1].name"
                :image-array="imagesSweet"
                :index="page * 3 + 1"
                topImage="331.45vw"
                topMiddle="6.3vw"
                variant="medium"
                container-pos-left="49.94vw"
                image-height="21.15vw"
            />

            <ImageBlock
                :id="imagesSweet[page * 3 + 2].id"
                :src="imagesSweet[page * 3 + 2].src"
                :name="imagesSweet[page * 3 + 2].name"
                :image-array="imagesSweet"
                :index="page * 3 + 2"
                topImage="331.45vw"
                topMiddle="6.3vw"
                variant="medium"
                container-pos-left="75vw"
                image-height="21.15vw"
            />
          </div>
        </transition>

      </div>
    </transition>
  </div>
</template>

<script>
import ImageBlock from "@/sections/desktop/Galleries/blocks/ImageBlock";

export default {
  name: "GalleryWeddingCake",
  components: {
    ImageBlock,
  },
  data() {
    return {
      page: 0,
      isOpened: false,
      imagesSweet: [
        {
          id: 'gallery-img-wedding-0',
          name: '',
          src: require('@/assets/img/galleries/wedding/first-0.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/first-0-opened.webp')
        },
        {
          id: 'gallery-img-wedding-1',
          name: '',
          src: require('@/assets/img/galleries/wedding/first-1.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/first-1-opened.webp')
        },
        {
          id: 'gallery-img-wedding-2',
          name: '',
          src: require('@/assets/img/galleries/wedding/first-2.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/first-2-opened.webp')
        },
        {
          id: 'gallery-img-wedding-3',
          name: '',
          src: require('@/assets/img/galleries/wedding/second-0.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/second-0-opened.webp')
        },

        {
          id: 'gallery-img-wedding-4',
          name: '',
          src: require('@/assets/img/galleries/wedding/second-1.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/second-1-opened.webp')
        },

        {
          id: 'gallery-img-wedding-5',
          name: '',
          src: require('@/assets/img/galleries/wedding/second-2.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/second-2-opened.webp')
        },

        {
          id: 'gallery-img-wedding-6',
          name: '',
          src: require('@/assets/img/galleries/wedding/third-0.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/third-0-opened.webp')
        },
        {
          id: 'gallery-img-wedding-7',
          name: '',
          src: require('@/assets/img/galleries/wedding/third-1.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/third-1-opened.webp')
        },
        {
          id: 'gallery-img-wedding-8',
          name: '',
          src: require('@/assets/img/galleries/wedding/third-3.webp'),
          srcGallery: require('@/assets/img/galleries/wedding/third-2-opened.webp')
        },
      ],
    }
  },
  watch: {
    page() {
    }
  },
  methods: {
    toPage(page) {
      this.page = page;
    },
    nextPage() {
      this.page++;
      if (this.page === 3) this.page = 0;
    },
    toggleOpen() {
      this.page = 0;
      this.isOpened = !this.isOpened;
    }
  }
}
</script>

<style scoped>
.gallery-wedding .arrow-next {
  position: absolute;


  left: 75.19vw;
  top: 314.2vw;

  width: 1.8vw;
  height: 1.3vw;
  cursor: pointer;
  /*z-index: 2;*/
}

.gallery-wedding .arrow-next path {
  fill: white;
  transition: 0.3s;
}

.gallery-wedding .arrow-next:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}

.btn-ellipse-first {
  position: absolute;
  left: 62.91vw;
  top: 314.32vw;
}

.btn-ellipse-second {
  position: absolute;
  left: 66.09vw;
  top: 314.32vw;
}

.btn-ellipse-third {
  position: absolute;
  left: 69.27vw;
  top: 314.32vw;
}

#gallery-wedding__img-opened{
  position: absolute;
  width: 160.5vw;
  height: 27.05vw;
  left: 80.35vw;
  top: 308.4vw;
}

.gallery-wedding__text-opened {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 4.06vw;
  font-family: blackest_heavy, serif;
  left: 55.93vw;
  top: 319.16vw;
  transition: 0.3s;
}

.svg__close {
  cursor: pointer;
  position: absolute;
  left: 51.22vw;
  top: 313.46vw;
  width: 4.32vw;
  height: 4.32vw;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.path-close {
  fill: white;
  transition: 0.3s;
}

.gallery-wedding__bg-opened {
  position: absolute;
  width: 100vw;
  height: 41.19vw;
  left: 0;
  top: 311.39vw;
  background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 55%);
}

.cls-1 {
  fill: #fc0584;
}

.cls-1, .cls-2, .cls-3 {
  stroke: #fc0584;
}

.cls-2, .cls-3 {
  fill: none;
}

.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 13px;
}

.cls-4 {
  fill: #fff;
  transition: 0.3s;
}

.gallery-wedding__text:hover .cls-4 {
  fill: #A91974;
}

.gallery-wedding__text svg {
  position: absolute;
  left: -9.85vw;
  width: 6vw;
  top: 1.1vw;
}

.gallery-wedding__text {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 5.67vw;
  font-family: blackest_heavy, serif;
  width: 40.93vw;
  height: 7.55vw;
  left: 42.6vw;
  top: 335vw;
  cursor: pointer;
  transition: 0.3s;
}

.gallery-wedding__text:hover {
  color: var(--color-magenta);
  transition: 0.3s;
}
#gallery-wedding__anim {
  position: absolute;
  width: 88.31vw;
  height: 23.03vw;
  left: 24.04vw;
  top: 310.6vw;
}

#gallery-wedding__img {
  position: absolute;
  width: 45.1vw;
  height: 41.19vw;
  left: -9.32vw;
  top: 311.39vw;
}

.gallery-wedding__bg-closed {
  position: absolute;
  background: linear-gradient(-90deg, rgba(252, 4, 131, 1) 0%, #9823E8 100%);
  width: 67.41vw;
  height: 33.78vw;
  left: 32.5vw;
  top: 314.32vw;
}
</style>