<template>
  <div>
    <div class="slide__1-ellipse-left-overlay-big "/>
    <div class="slide__1-ellipse-left-overlay-medium "/>
    <div class="slide__1-ellipse-left-violet-big ellipse-bg-gradient  "/>
    <img id="slide__1-cake-left" src="../../../assets/img/slider2a-d6a07479.webp" alt="slide-1-cake-left"/>
    <div class="slide__1-ellipse-left-violet-tiny ellipse-bg-gradient  "/>

    <div class="slide__1-ellipse-right-overlay-big  "/>
    <div class="slide__1-ellipse-right-overlay-medium  "/>
    <div class="slide__1-ellipse-right-violet-big ellipse-bg-gradient  "/>
    <img id="slide__1-cake-right" src="../../../assets/img/slider2b.webp" alt="slider-1-cake-right"/>
    <div class="slide__1-ellipse-right-violet-tiny ellipse-bg-gradient  "/>
    <p class="slide__1-text-salt ">Édes vagy sós?</p>
    <p class="slide__1-text-order ">Kiszállítással <br/>vagy elvitellel?</p>
  </div>
</template>

<script>
export default {
  name: "SliderSecond"
}
</script>

<style scoped>
.slide__1-text-order{
  position: absolute;
  text-align: center;
  line-height: 13.05vw;
  color: rgba(255, 255, 255, 1);
  font-size: 11.94vw;
  font-weight: 600;
  font-style: normal;
  font-family: blackest_heavy, Arial;
  white-space: pre;
  width: 87.22vw;
  height: 28.88vw;
  left: 6.38vw;
  top: 69.44vw;
}
.slide__1-text-salt{
  position: absolute;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 8.61vw;
  font-weight: 400;
  font-family: blackest_regular, Arial,serif;
  white-space: pre;
  width: 57.77vw;
  height: 11.38vw;
  left: 21.11vw;
  top: 53.88vw;
}
.slide__1-ellipse-right-violet-tiny{
  position: absolute;
  -ms-transform: rotate(30.00deg);
  -webkit-transform: rotate(30.00deg);
  transform: rotate(30.00deg);
  width: 7.67vw;
  height: 7.67vw;
  left: 85.6vw;
  top: 36.85vw;
  border-radius: 50%;
}
#slide__1-cake-right{
  position: absolute;
  opacity: 0.92;
  width: 68.61vw;
  height: 68.8vw;
  left: 49.72vw;
  top: 48.33vw;
}
.slide__1-ellipse-right-violet-big{
  position: absolute;
  -ms-transform: rotate(32.00deg);
  -webkit-transform: rotate(32.00deg);
  transform: rotate(32.00deg);
  width: 49.48vw;
  height: 49.48vw;
  left: 59.7vw;
  top: 57.83vw;
  border-radius: 50%;
}
.slide__1-ellipse-right-overlay-medium{
  position: absolute;
  -ms-transform: rotate(32.00deg);
  -webkit-transform: rotate(32.00deg);
  transform: rotate(32.00deg);
  opacity: 0.25;
  background-color: rgba(255, 235, 245, 1);
  width: 59.6vw;
  height: 59.6vw;
  left: 58.31vw;
  top: 54.65vw;
  border-radius: 50%;
}
.slide__1-ellipse-right-overlay-big{
  position: absolute;
  -ms-transform: rotate(-4.00deg);
  -webkit-transform: rotate(-4.00deg);
  transform: rotate(-4.00deg);
  opacity: 0.12;
  background-color: rgba(255, 235, 245, 1);
  width: 96.12vw;
  height: 96.12vw;
  left: 58.29vw;
  top: 38.55vw;
  border-radius: 50%;
}

.slide__1-ellipse-left-overlay-big{
  position: absolute;
  -ms-transform: rotate(-4.00deg);
  -webkit-transform: rotate(-4.00deg);
  transform: rotate(-4.00deg);
  opacity: 0.12;
  background-color: white;
  width: 107.81vw;
  height: 107.81vw;
  left: -54.73vw;
  top: 17.45vw;
  border-radius: 50%;
}

.slide__1-ellipse-left-overlay-medium{
  position: absolute;
  -ms-transform: rotate(-4.00deg);
  -webkit-transform: rotate(-4.00deg);
  transform: rotate(-4.00deg);
  opacity: 0.25;
  background-color: rgba(255, 235, 245, 1);
  width: 77.14vw;
  height: 77.14vw;
  left: -24.56vw;
  top: 35.21vw;
  border-radius: 50%;
}

.slide__1-ellipse-left-violet-big{
  position: absolute;
  -ms-transform: rotate(32.00deg);
  -webkit-transform: rotate(32.00deg);
  transform: rotate(32.00deg);
  width: 66.9vw;
  height: 66.9vw;
  left: -15.11vw;
  top: 41.56vw;
  border-radius: 50%;
}

#slide__1-cake-left{
  position: absolute;
  opacity: 0.92;
  width: 124.44vw;
  height: 92.77vw;
  left: -24.44vw;
  top: 32.22vw;
}

.slide__1-ellipse-left-violet-tiny{
  position: absolute;
  box-sizing: content-box;
  -ms-transform: rotate(30.00deg);
  -webkit-transform: rotate(30.00deg);
  transform: rotate(30.00deg);
  width: 15.89vw;
  height: 15.89vw;
  left: 13.07vw;
  top: 106.28vw;
  border-radius: 50%;
}
</style>