<template>
  <div id="app">
    <router-view/>
    <transition name="fade" mode="out-in">
      <div v-if="gallery" key="app-gallery">
        <Gallery/>
      </div>
    </transition>


  </div>
</template>

<script>
import Gallery from "@/components/desktop/Gallery";

export default {
  components: {
    Gallery
  },
  computed: {
    gallery: function () {
      return this.$store.getters.gallery;
    }
  },
}
</script>
<style>
</style>
